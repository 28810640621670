#my-page{
    .page-title{
        font-size: 20px;
        color: $text-main;
        line-height: 32px;
        margin-bottom: 23px;
        font-weight: bold;
    }
    .user{
        display: flex;
        flex-wrap: wrap;
        align-items: center;

            // .image{
            //     background-repeat: no-repeat;
            //     background-size: cover;
            //     img{
            //         vertical-align: middle;
            //         border-radius: 50%;
            //     }
            // }
            .name{
                @include br739{
                    width: calc(100% - 80px);
                }
                input{
                    border: 1px solid #808080;
                    height: 48px;
                    font-size: 20px;
                    font-weight: 700;
                    width: 496px;
                    @include br1079{
                        width: 300px;
                    }
                }
            }
            .group-btn{
                margin-left: 16px;
                display: flex;
                align-items: center;
                @include br739{
                    flex: 0 0 100%;
                    margin-left: 0;
                }
                .btn{
                    font-size: 13px;
                    color: #fff;
                }
                .btn-white{
                    background: #fff;
                    color: $text-disable !important;
                    border-color: $text-disable;
                    width: 116px;
                    height: 29px;
                    font-weight: 400 !important;
                }
            }

        .image{
            width: 64px;
            height: 64px;
            display: flex;
            border: 1px solid $line;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            position: relative;
            img {
                border-radius: 50%;
                width: 64px;
            height: 64px;
                object-fit: cover;
            }
            .action{
                width: 24px;
                height: 24px;
                border: 1px solid $line;
                border-radius: 50%;
                position: absolute;
                bottom: 3px;
                right: 3px;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                button{
                    border: none;
                    display: flex;
                    cursor: pointer;
                }
                ul{
                    position: absolute;
                    border: 1px solid $line;
                    border-radius: 3px;
                    background: #FFFFFF;
                    z-index: 1;
                    width: 162px;
                    height: 63px;
                    top: 100%;
                    left: 0;
                    right: auto;
                    padding: 5px 3px;
                    li{
                        padding-left: 12px;
                        &:hover{
                            background-color: $background-active;
                        }
                        button{
                            font-size: 12px;
                            color: $text-main;
                            border: none;
                            width: 100%;
                            background-color: transparent;
                            font-weight: 400;
                        }
                        &:first-child {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
        .name{
            font-size: 20px;
            font-weight: 700;
            color: $text-main;
            margin-left: 16px;
            padding-top: 1px;
        }
        .edit{
            display: flex;
            margin-left: 5px;
            padding-top: 7px;
            cursor: pointer;
            &:hover{
                path{
                    fill: $main-color;
                }
                line{
                    stroke: $main-color;
                }
            }
        }
    }
    .past-output{
        .ant-collapse-header {
            color: $text-main;
        }
        .title{
            font-size: 16px;
            font-weight: 700;
            color: $text-accent;
            margin-bottom: 18px;
        }
        .panel{
            color: $text-main;
            background: #FFFFFF;
            border: 1px solid $line;
            border-radius: 8px;
            // padding: 17px 15px;
            margin-bottom: 16px;
            cursor: pointer;
            transition: .2s all;
            overflow: hidden;
            font-weight: bold;
            &.open{
                background: $background-active;
                .panel-heading{
                    .icon{
                        transform: rotate(180deg);
                    }
                }
            }
            .panel-heading{
                h3{
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: $text-main;
                    .icon{
                        margin-right: 15px;
                        display: flex;
                    }
                }
            }
            .panel-collapse{
                font-size: 14px;
                color: $text-main;
                line-height: 22.4px;
                padding-top: 10px;
                margin-bottom: -3px;
                display: none;
            }
        }
        .panel:hover {
            background: $background-active;
        }
        .pagination{
            ul{
                display: flex;
                li{
                    margin-right: 7px;
                    &.current, &:hover{
                        a{
                            color: $main-color;
                            border-color: $main-color;
                            path{
                                fill: $main-color;
                            }
                        }
                    }
                    a{
                        font-size: 14px;
                        color: $line;
                        background: #FFFFFF;
                        border: 1px solid $line;
                        border-radius: 3px;
                        width: 28px;
                        height: 28px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        transition: .2s all;
                    }
                }
            }
        }
    }
    .filter-date{
        margin-top: 33px;
        label{
            color: $text-main;
            margin-bottom: 10px;
        }
        select{
            @include br739{
                width: calc(50% - 30px);
            }
        }
        span{
            font-size: 14px;
            margin: 0 4px;
        }
        .ant-select-selection-item {
            padding-right: 10px;
        }
        .ant-select-arrow {
        right: 3px;
        transform: translateY(-50%);

        svg {
            width: 9px;
        }
        }
    }

    .table{
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 8px;
        overflow-x: auto;
        margin-top: 16px;
        .ant-table-content {
            &::-webkit-scrollbar {
                height: 10px;
            }
            &::-webkit-scrollbar-track {
                display: none;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $line;
                border-radius: 8px;
            }
        }
        table{
            width: 100%;
            text-align: center;
            border-collapse: collapse;
            background-color: #fff;
            border-radius: 8px;
            td, th {
                padding: 8px 4px;
                border-radius: 8px;
                &:nth-child(1){
                    padding-left: 42px;
                }
            }
            th{
                font-size: 14px;
                font-weight: 700;
                color: $text-accent;
                height: 52px;
                &.date{
                    width: 185px;
                    min-width: 185px;
                }
                &.sales{
                    width: 170px;
                    min-width: 170px;
                }
                &.kpi{
                    width: 191px;
                    min-width: 191px;
                    text-align: right;
                    padding: 8px 22px
                }
            }
            td{
                font-size: 14px;
                color: $text-main;
                height: 56px;
                position: relative;
                padding-top: 18px;
                padding-bottom: 18px;
                // vertical-align: baseline;
                &.date{
                    font-size: 13px;
                    font-weight: 700;
                    color: $text-main;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                }
                &.sales{
                    font-size: 13px;
                    color: $text-main;
                    b{
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
                &.kpi{
                    .inner{
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        padding-right: 17px;
                        > div{
                            display: inline-flex;
                        }
                    }
                    .percent{
                        flex-direction: column;
                        font-size: 13px;
                        span{
                            font-size: 16px;
                            font-weight: 700;
                            margin-bottom: -3px;
                        }
                        b{
                            font-size: 20px;
                            font-weight: 700;
                        }
                    }
                    ul{
                        margin-top: 5px;
                        li{
                            .name{
                                width: 40px;
                                min-width: 40px;
                                display: inline-block;
                                text-align: right;
                                margin-right: 4px;
                            }
                            .value{
                                font-size: 12px;
                                color: $text-disable;
                                line-height: 21px;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
            tr{
                border-bottom: 1px solid #ccc;
                &.active, &:hover {
                    background-color: $background-active;
                }
                &.active{
                    td{
                        padding-top: 14px;
                        padding-bottom: 14px;
                    }
                }
            }
        }
    }

    .table-dashboard-detail .ant-table-thead > tr > th, .table-dashboard-detail .ant-table-tbody > tr > td {
      min-width: 180px;
    //   max-width: 180px;
    }

    .table-dashboard-detail {
        .ant-table-thead {
            p {
                margin-bottom: 0;
                display: block;
                max-width: 500px;
                overflow: hidden;
                white-space: nowrap; 
                text-overflow: ellipsis;
                margin-right: auto;
            }
        }
    }

    .column-date {
      vertical-align: middle !important;
      font-weight: 700;
    }

    .percentage-value {
      padding-top: 10px;
    }

}
