#dashboard-creation {
  .container {
    max-width: 1200px;
  }
  .wrapper {
    padding: 82px 0;
    margin-left: 0;
  }
  h2.title {
    font-size: 20px;
    font-weight: 700;
    color: $text-main;
    margin-bottom: 24px;
  }
  form {
    .form-groups {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      margin-top: 25px;
      .form-group {
        margin-right: 16px !important;
        @include br739 {
          width: 100%;
          margin-right: 0;
        }
      }
    }
    .form-group {
      + .form-group {
        margin-top: 25px;
      }
      &.target-value,
      &.alert-line {
        label {
          font-size: 13px;
          font-weight: 400;
          color: $text-disable;
          margin-bottom: 3px;
        }
        input::placeholder {
          color: $line;
        }
        .ant-form-item-explain {
          width: 100%;

          .ant-form-item-explain-error {
            white-space: normal;
          }
        }
      }
      &.kpi {
        input::placeholder {
          color: $line;
        }

        .btn-delete {
          width: 75px;
          height: 38px;
          border: 1px solid #f86767;
          border-radius: 3px;
          color: #f86767;
          font-size: 13px;
          margin-left: 8px;
        }
      }
      &.unit {
        input {
          &::placeholder {
            font-size: 11px;
          }
          margin-left: 8px;
          padding: 0 8px;
        }
        & .ant-form-item-explain-error {
          @media screen and (min-width: 1200px) {
            margin-left: -55px;
          }
        }
      }
      &.form-wrapper {
        margin-top: 25px;
        border: 1px solid #ccc;
        max-width: 1160px;
        border-radius: 8px;
        padding: 16px;
        padding-bottom: 30px;

        @media screen and (min-width: 1200px) {
          width: 1160px;
        }
      }
      label {
        margin-bottom: 10px;
        color: $text-main;
      }
      input,
      select {
        font-family: 'Noto Sans', 'Noto Sans JP' !important;
        background: #fff;
        border: 1px solid $line;
        border-radius: 3px;
        height: 38px;
        padding: 0 13px;
        font-size: 14px;
        color: #333;
        letter-spacing: 0.05em;
        max-width: 100%;
        box-shadow: none;
        &::placeholder {
          color: $line;
        }
        &.w-437 {
          width: 437px;
        }
        &.w-94 {
          width: 94px;
        }
        &.w-77 {
          width: 77px;
        }
        &.w-248 {
          width: 248px;
        }
        &.w-953 {
          width: 715px;

          @media screen and (min-width: 1200px) {
            width: 953px;
          }
        }
        &.w-1067 {
          width: 715px;

          @media screen and (min-width: 1200px) {
            width: 1067px;
          }
        }
        &.w-53 {
          width: 53px;
        }
        @include br739 {
          width: 100% !important;
          max-width: 100%;
        }
        &:focus,
        &:hover {
          border-color: #808080;
        }
      }
      select {
        appearance: none;
        width: 77px;
        padding: 0 11px;
      }
      & .calculation {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-top: 25px;

        & .group-calculation {
          display: flex;
          align-items: flex-end;

          & .group-button {
            display: flex;
          }
        }

        input,
        button {
          @include br739 {
            margin-bottom: 10px;
          }
        }
        label {
          font-size: 13px;
          font-weight: 400;
          color: $text-disable;
          margin-bottom: 2px;
          flex: 0 0 100%;
        }
        select.btn-grey {
          width: 80px;
        }
        .btn-grey {
          background: $line;
          border-radius: 3px;
          border: none;
          color: #fff;
          line-height: 28px;
          height: 28px;
          width: 78px;
          margin-right: 9px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          @include br739 {
            width: 74px;
            margin-top: 10px;
          }
          &.btn-erase {
            width: auto;
            font-size: 13px;
            padding: 0 23px;
          }
          &:disabled {
            cursor: default;
          }
          &.w-114 {
            width: 114px;
          }
        }
        .btn-white {
          border: 1px solid $line;
          box-sizing: border-box;
          border-radius: 3px;
          line-height: 27px;
          font-size: 13px;
          padding: 0 11px;
          margin-right: 9px;
          color: $line;
        }
        input {
          margin-right: 9px;
        }
        .ant-form-item-control-input {
          align-items: flex-end;
          .ant-select-status-error {
            .ant-select-selector {
              border: 1px solid $alert;
            }
          }
          .ant-select-selector {
            background: $line;
            border: none;
            height: 28px;
            width: 78px;
            .ant-select-selection-item {
              line-height: 28px;
            }
            .ant-select-selection-placeholder {
              line-height: 28px;
              color: #fff;
            }
          }
          .ant-select-arrow {
            color: #fff;
          }
        }
        .halfwidth-number {
          input {
            border: 1px solid $line;
            width: 78px;
            height: 28px;

            &::placeholder {
              font-size: 11px;
              line-height: 28px;
            }
          }
          border-radius: 3px;
          font-size: 13px;
          margin-right: 9px;
          color: #333;
          width: 78px;
          height: 28px;
          padding: 4px 11px;
          &::placeholder {
            color: $line;
            font-size: 12px;
          }
          &:focus {
            border-color: #808080;
          }
        }
        .ant-row {
          .ant-input-status-error {
            border-color: $alert;
          }
        }
      }
      .ant-select-selector {
        border-radius: 3px;
      }
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: none;
        border-color: #808080;
      }
      .ant-input-status-error {
        border-color: $alert;
      }
      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #808080;
      }
    }
    & > .form-item-wrapper {
      & .ant-form-item-control-input-content {
        display: flex;
        flex-flow: column;

        & > .kpi {
          order: 1;
        }
      }
    }
    .btn-add-kpi {
      font-size: 13px;
      background: transparent;
      color: $main-color;
      line-height: 28px;
      height: 29px;
      padding: 0 23px;
      font-weight: 400 !important;
      margin-top: 16px;
      border-radius: 3px;
      border: 1px solid $main-color;
      @include br739 {
        width: 100%;
      }
    }
    .group-bottom {
      text-align: center;
      margin-top: 38px;
      margin-right: 150px;
      @include br739 {
        margin-right: 0;
      }
      .btn {
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 34px;
        height: 34px;
        padding: 0 23px;
        border-radius: 3px;
        margin: 0 10px 10px;
        color: #ffffff;
        border: 1px solid $main-color;
        @include br739 {
          margin: 0 5px 10px;
        }
        &.btn-return {
          background-color: $line;
          border-color: $line;
          font-weight: 400 !important;
        }
        &:hover {
          color: #ffffff;
        }
        &-create {
          background-color: $main-color;
          width: 216px;
        }
      }
    }

    .ant-form-item-explain {
      position: absolute;
      width: auto;
      white-space: normal;
      top: 100%;
      z-index: 9;

      .ant-form-item-explain-error {
        white-space: nowrap;
      }
    }
  }
}
