html {
  height: 100%;
}

.ant-dropdown .ant-dropdown-menu ul .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-dropdown {
  &:hover > #project-details .block-column .column .fill-text .color-change {
    background-color: #3cb371;
    visibility: visible;
    z-index: 1;
  }
  &::before {
    content: none !important;
  }
}

.circle {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #cccccc;
}
.position-relative {
  position: relative;
}

// .full-layout:hover > .box-fixed-bottom {
//   opacity: 1;
//   visibility: visible;
// }

.pseudo-focus {
  border-color: #60bf87 !important;
  box-shadow: 0 0 0 2px rgba(60, 179, 113, 0.2) !important;
  border-right-width: 1px !important;
  outline: 0 !important;
}

#project-details {
  .jtk-group-expanded[data-jtk-managed] {
    top: 0;
    left: 0;
  }
  .search-input-rmpadding {
    .ant-select-selection-search-input {
      padding: 0;
    }
  }
  padding-left: 40px;
  &.mind-map {
    .main-content {
      padding: 27px 40px;
      @include br739 {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .content {
      justify-content: space-between;
    }
    .block-column {
      max-width: calc(100% - 270px);
      @include br739 {
        max-width: 100%;
      }
    }
    .other-user {
      height: 100%;
      @include br739 {
        background: rgba(0, 0, 0, 0.3);
        position: fixed;
        z-index: 100;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .body {
        background: #ffffff;
        border: 1px solid $line;
        width: 246px;
        min-width: 246px;
        border-radius: 3px;
        height: 100%;
        @include br739 {
          width: calc(100% - 70px);
        }
        .close-modal {
          width: 28px;
          height: 28px;
          display: none;
          background: #fff;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -14px;
          right: -15px;
          border: 1px solid $main-color;
          transition: 0.2s all;
          @include br739 {
            display: flex;
          }
          &:hover {
            background-color: $main-color;
            path {
              fill: #fff;
            }
          }
        }
        @include br739 {
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .inner {
          padding: 15px;
          @include br739 {
            padding: 25px;
          }
        }
      }
      h3 {
        font-size: 16px;
        font-weight: 700;
        color: $text-main;
        margin-left: -3px;
        margin-right: -3px;
        margin-bottom: 19px;
      }
      .item {
        &--active {
          background: #f4fbf4;
        }
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        border: 1px solid $line;
        border-radius: 3px;
        padding: 15px;
        .name {
          font-size: 14px;
          font-weight: 700;
          color: $text-main;
          margin-left: 8px;
        }
        .time {
          font-size: 14px;
          margin-left: auto;
          color: $text-disable;
        }
        .delete {
          display: flex;
          margin-left: auto;
          margin-right: 8px;
          border: none;
          background: #fff;
          &:hover {
            path {
              fill: $main-color;
            }
          }
        }
      }
    }
  }
  .main-content {
    margin-top: 0 !important;
    padding: 0 40px 24px;
    // padding-left: 86px !important;
    > h3.title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 26px;
      span {
        font-weight: 400;
      }
    }
  }
  .top {
    // margin-bottom: 22px;
    .row {
      @include br739 {
        flex-direction: column;
      }
    }
    .col-left {
      h3 {
        font-size: 20px;
        padding-top: 3px;
        span {
          font-weight: 400;
        }
      }
    }
    .col-right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      @include br739 {
        justify-content: flex-start;
        margin-top: 15px;
      }
      .form-group {
        display: flex;
        align-items: center;
        margin-right: 24px;
        label {
          margin-right: 9px;
          margin-bottom: 2px;
          white-space: nowrap;
        }
        select {
          width: 254px;
          @include br1079 {
            width: calc(100% - 51px);
          }
        }
      }
      .btn-border-main {
        background-color: #fff;
        @include br739 {
          width: 100%;
          margin-top: 15px;
        }
        &.btn-task-list {
          font-size: 13px;
          font-weight: 400 !important;
          color: #fff !important;
          background-color: $main-color;
        }
      }
    }
  }

  .content {
    display: flex;
    min-height: calc(100vh - 165px);
    padding-top: 20px;
  }
  .search-box {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $line;
      border-radius: 8px;
    }
    width: 230px;
    min-width: 230px;
    background-color: #fff;
    padding: 20px 15px;
    overflow-y: auto;
    height: calc(100vh - 170px);
    margin-right: 20px;
    @include br739 {
      display: none;
    }
    h3 {
      font-size: 13px;
      color: $text-main;
      margin-bottom: 9px;
      font-weight: 700;
    }
    .w-100 {
      width: 100%;
      margin-bottom: 18px;
    }
    input {
      margin-bottom: 18px;
      // color: #ccc;
      padding: 0 10px;
    }
    .add-project {
      font-size: 14px;
      font-weight: 700;
      color: $main-color;
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      border: 0;
      background: transparent;
      cursor: pointer;

      svg {
        margin-right: 9px;
      }
    }
    .edit-project {
      border: none;
      background: transparent;
      cursor: pointer;
    }
    .item {
      &--active {
        background: #f4fbf4;
      }
      border: 1px solid $line;
      border-radius: 3px;
      padding: 9px 15px 10px;
      margin-bottom: 16px;
      ul {
        li {
          display: flex;
          justify-content: space-between;
          + li {
            margin-top: 7px;
          }
          &.title {
            .name {
              font-weight: 700;
              color: $main-color;
            }
          }
          .name {
            font-size: 14px;
            color: $text-disable;
          }
          .value {
            font-size: 14px;
            font-weight: 700;
            color: $text-main;
          }
        }
      }
    }
  }

  .block-column {
    .jtk-hover {
      z-index: 51 !important;
    }
    .jtk-connector {
      z-index: 50;
      cursor: pointer;
    }
    .jtk-endpoint {
      z-index: 50;
    }
    .jtk-overlay {
      z-index: 50;
    }
    svg .jtk-connector-outline {
      z-index: 50;
    }
    display: flex;
    width: 1638px;
    overflow-x: auto;
    overflow-y: auto;
    height: calc(100vh - 170px);
    border: 1px solid $line;
    .column {
      & .delete-mindmap {
        & > span {
          width: 12px;
          height: 12px;

          & > svg {
            width: 12px;
            height: 12px;
          }
        }
      }
      &:hover .delete-mindmap {
        transition: 0.5s;
        opacity: 1;
        cursor: pointer;
        display: flex !important;
      }
      background-color: white;
      // width: 335px;
      min-width: 335px;
      border-right: 1px solid $line;
      padding: 15px;
      // height: calc(100vh - 170px);
      // overflow-y: auto;
      position: relative;
      &--new {
        background: #faf9f8;
      }
      .root-wrapper {
        min-height: 38px;
        ::after {
          content: '';
          clear: both;
          display: table;
        }
      }
      .fill-text {
        .color-change {
          background-color: white;
          z-index: 301;
          opacity: 0;
          visibility: hidden;
          margin-top: 8px;
          position: absolute;
          top: 30px;
          right: 10%;
          display: flex;
          justify-content: center;
          .dropdown {
            z-index: 301;
            position: relative;
            #color-icon {
              // color: red;
              // &:hover {
              //   background-color: #3cb371;
              // }
              &:hover + #dropdown-menu {
                transition: 0.25s all;
                opacity: 1;
                visibility: visible;
              }
            }
            &:hover {
              .tooltip {
                opacity: 1;
              }
            }
            #dropdown-menu {
              &:hover {
                visibility: visible;
                opacity: 1;
              }
            }
            .tooltip {
              background: $line;
              color: #fff;
              text-align: center;
              font-size: 13px;
              position: absolute;
              bottom: 100%;
              padding: 2px 8px;
              border-radius: 3px;
              opacity: 0;
              transition: 0.2s all;
              z-index: 1;
              &::after {
                content: '';
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 6px solid $line;
                display: inline-block;
                border-radius: 50%;
                border: 1px solid #cccccc;
                &.black {
                  background: $text-main;
                }
                &.line {
                  background: $line;
                }
                &.alert {
                  background: $alert;
                }
                &.blue {
                  background: $blue;
                }
                &.orange {
                  background: $orange;
                }
              }
              &.open {
                .dropdown-menu {
                  transition: 0.25s all;
                  opacity: 1;
                  visibility: visible;
                }
              }
              .dropdown-menu {
                // z-index: 200;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                // z-index: 1;
              }
            }
            a {
              padding: 0 16px;
              border: 1px solid #cccccc;
              box-sizing: border-box;
              border-radius: 3px;
              width: 165px;
              display: flex;
              height: 41px;
              align-items: center;
              justify-content: space-between;

              & .color-icon {
                width: 18px;
                height: 18px;
                margin-bottom: 7px;
              }

              & > button {
                width: 53px;
                height: 25px;
                border: 1px solid $main-color;
                color: $main-color;
                text-align: center;
                line-height: 23px;
                padding: 0;

                & > span {
                  font-size: 13px;
                }
              }
            }
            &.open {
              .dropdown-menu {
                transition: 0.25s all;
                opacity: 1;
                visibility: visible;
              }
            }
            // .dropdown-menu:hover {
            //   visibility: visible;
            // }
            .dropdown-menu {
              position: absolute;
              background-color: #fff;
              left: 0;
              top: 100%;
              width: 60px;
              border: 1px solid #cccccc;
              border-radius: 3px;
              opacity: 0;
              visibility: hidden;
              // z-index: 200;
              transition: 0.2s all;
              ul {
                padding: 6px 2px;
                li {
                  position: relative;
                  text-align: right;
                  padding: 0.5px 9px;
                  &.active,
                  &:hover {
                    background-color: $background-active;
                  }
                  svg {
                    position: absolute;
                    left: 10px;
                    top: 5px;
                  }
                }
              }
            }
          }
        }
        border-radius: 20px;
        .ant-input-sm::-webkit-scrollbar {
          display: none;
        }
        .ant-input-sm {
          resize: none;
          height: 38px;
        }
        &:hover > .setting,
        &:hover > .setting-drag {
          transition: 0.5s;
          opacity: 1;
          cursor: pointer;
          display: flex !important;
        }
        &:hover > .color-change {
          transition: 0.5s;
          opacity: 1;
          visibility: visible;
        }
        &:hover > .dropdown-menu {
          transition: 0.5s;
          opacity: 1;
          visibility: visible;
        }

        &.prevent-popup {
          &:hover > .setting {
            opacity: 0;
            display: none !important;
          }
          &:hover > .color-change {
            opacity: 0;
            visibility: visible;
            display: none !important;
          }
        }
        &.has-uncheck-comment {
          .setting {
            opacity: 1 !important;
            transition: 0.5s;
            cursor: pointer;
            display: flex !important;
          }
        }
      }
      &:nth-child(1) {
        border-left: none;
      }
      &.bg-white {
        background-color: #fff;
      }
      &.bg-grey {
        background-color: #f8f8f8;
      }
      &.bg-active {
        border: 2px solid $main-color;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px 0px 0px 3px;
        background-color: $background-active;
        .fill-text {
          color: $text-disable;
          border-color: $line;
          background: #fff;
          &::after {
            // content: url("../images/line-1.2.png");
            top: -23px;
          }
        }
      }
      b {
        display: block;
        font-size: 14px;
        color: $text-main;
        font-weight: 700;
        line-height: 1.45;
        margin-bottom: 18px;
        position: relative;
      }
      & .cut-string {
        width: 280px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .setting {
        opacity: 0;
        cursor: none;
        display: flex;
        align-items: center;
        border: 1px solid #8e8e8e;
        border-radius: 3px;
        padding: 2px 4px;
        position: absolute;
        right: 0;
        top: -3px;
        // min-width: 66px;
        z-index: 300;
        background: #fff;
        // &:hover > a {
        //   color: $main-color !important;
        //   path {
        //     fill: $main-color;
        //   }
        // }
        button {
          display: flex;
          font-size: 12px;
          color: $text-disable;
          font-weight: 400;
          padding: 0 3.5px;
          border: 0;
          background: transparent;
          cursor: pointer;
          // &:hover {
          //   color: $main-color;
          //   path {
          //     fill: $main-color;
          //   }
          // }
        }
        &-drag {
          position: absolute;
          left: 0;
          z-index: 300;
          top: -3px;
          border: 1px solid #8e8e8e;
          padding: 2px 4px;
          border-radius: 3px;
          color: #8E8E8E;
          background: #fff;
          opacity: 0;
          cursor: none;
          align-items: center;

          & > .anticon {
            margin-right: 5px;
            display: inline-block;
          }
        }
      }
      .add-task {
        font-size: 14px;
        font-weight: 700;
        color: $main-color;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 9px;
        }
      }
      .field {
        color: $text-disable;
        font-size: 14px;
        padding: 9px 16px;
        border: 1px solid #cccccc;
        background-color: #fff;
        border-radius: 100px;
        margin-bottom: 16px;
        ul {
          li {
            display: flex;
            justify-content: space-between;
            + li {
              margin-top: 7px;
            }
            &.title {
              .name {
                font-weight: 700;
                color: $main-color;
              }
            }
            .name {
              font-size: 14px;
              color: $text-disable;
            }
            .value {
              font-size: 14px;
              font-weight: 700;
              color: $text-main;
            }
          }
        }
      }
    }
    .column:focus {
      background-color: #f8f8f8 !important;
    }

    .block-column {
      .jtk-endpoint {
        z-index: 50;
      }
      .jtk-overlay {
        z-index: 50;
      }
      svg .jtk-connector-outline {
        z-index: 50;
      }
      display: flex;
      width: 1638px;
      overflow-x: auto;
      overflow-y: auto;
      border: 1px solid $line;
      .column {
        background-color: white;
        width: 335px;
        min-width: 335px;
        border-right: 1px solid $line;
        padding: 15px;
        height: 100%;
        padding-bottom: 100px;
        // border-right: none;
        position: relative;
        &--new {
          background: #faf9f8;
        }
        .root-wrapper {
          min-height: 38px;
          ::after {
            content: '';
            clear: both;
            display: table;
          }
        }
        .fill-text {
          &:hover > .setting {
            transition: 0.5s;
            opacity: 1;
            cursor: pointer;
            display: flex !important;
          }
          &:hover > .color-change {
            transition: 0.5s;
            opacity: 1;
            visibility: visible;
          }
          &.prevent-popup {
            &:hover > .setting {
              opacity: 0;
              display: none !important;
            }
            &:hover > .color-change {
              opacity: 0;
              visibility: visible;
              display: none !important;
            }
          }
          &::after {
            // content: url("../images/line-3.png");
            position: absolute;
            right: -34px;
            top: 17px;
            z-index: 1;
          }
        }
      }
      .fill-text {
        border-radius: 20px;
        .ant-input-sm::-webkit-scrollbar {
          display: none;
        }
        .ant-input-sm {
          resize: none;
          height: 38px;
        }
        font-size: 14px;
        line-height: 22.4px;
        // border: 1px solid $alert;
        border-radius: 30px;
        // padding: 7px 16px;
        position: relative;
        &::after {
          // content: url("../images/line-1.png");
          position: absolute;
          left: 100%;
          top: -42px;
          z-index: 1;
        }
        &:hover .setting {
          transition: 0.25s;
          display: block;
        }
      }
      .color-change {
        background-color: white;
        z-index: 300;
        opacity: 0;
        visibility: hidden;
        margin-top: 8px;
        position: absolute;
        top: 30px;
        right: 50%;
        display: flex;
        justify-content: center;
        .dropdown {
          position: relative;
          &:hover {
            .tooltip {
              opacity: 1;
            }
          }
          .tooltip {
            background: $line;
            color: #fff;
            text-align: center;
            font-size: 13px;
            position: absolute;
            bottom: 100%;
            padding: 2px 8px;
            border-radius: 3px;
            opacity: 0;
            transition: 0.2s all;
            z-index: 1;
            &::after {
              content: '';
              width: 0;
              height: 0;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-top: 6px solid $line;
              display: inline-block;
              border-radius: 50%;
              border: 1px solid #cccccc;
              &.black {
                background: $text-main;
              }
              &.line {
                background: $line;
              }
              &.alert {
                background: $alert;
              }
              &.blue {
                background: $blue;
              }
              &.orange {
                background: $orange;
              }
            }
            &.open {
              .dropdown-menu {
                transition: 0.25s all;
                opacity: 1;
                visibility: visible;
              }
            }
            .dropdown-menu {
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          a {
            padding: 0 16px;
            border: 1px solid #cccccc;
            box-sizing: border-box;
            border-radius: 3px;
            width: 108px;
            display: inline-flex;
            height: 38px;
            align-items: center;
          }
          .circle {
            width: 16px;
            height: 16px;
            display: inline-block;
            border-radius: 50%;
            border: 1px solid #cccccc;
            &.black {
              background: $text-main;
            }
            &.line {
              background: $line;
            }
            &.alert {
              background: $alert;
            }
            &.blue {
              background: $blue;
            }
            &.orange {
              background: $orange;
            }
          }
          &.open {
            .dropdown-menu {
              transition: 0.25s all;
              // opacity: 1;
              // visibility: visible;
            }
          }
          .dropdown-menu {
            position: absolute;
            background-color: #fff;
            left: 0;
            top: 100%;
            width: 60px;
            border: 1px solid #cccccc;
            border-radius: 3px;
            // opacity: 0;
            // visibility: hidden;
            // z-index: 200;
            transition: 0.2s all;
            ul {
              padding: 6px 2px;
              li {
                position: relative;
                text-align: right;
                padding: 0.5px 9px;
                &.active,
                &:hover {
                  background-color: $background-active;
                }
                svg {
                  position: absolute;
                  left: 10px;
                  top: 5px;
                }
              }
            }
          }
          .dropdown-menu:hover {
            visibility: visible;
          }
        }
      }
    }
    .column-2 {
      .field + .field {
        margin-left: 17px;
      }
    }
    .column-4 {
      .add-task-box {
        @include br739 {
          background: rgba(0, 0, 0, 0.3);
          position: fixed;
          z-index: 100;
          top: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .body {
          background: #fff;
          position: absolute;
          top: 0;
          z-index: 1;
          width: 274px;
          border: 1px solid $line;
          box-sizing: border-box;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          padding: 24px;
          left: -15px;
          @include br739 {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 3px;
          }
        }
        .close-modal {
          width: 28px;
          height: 28px;
          display: none;
          background: #fff;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -14px;
          right: -15px;
          border: 1px solid $main-color;
          transition: 0.2s all;
          @include br739 {
            display: flex;
          }
          &:hover {
            background-color: $main-color;
            path {
              fill: #fff;
            }
          }
        }
        .inner {
          .add-task {
            margin-bottom: 24px;
          }
          .checkbox {
            padding: 13px 8px;
            &.bg-grey {
              background-color: #f8f8f8;
              padding: 6px 8px;
            }
            &.pl-24 {
              padding-left: 24px;
            }
            a {
              display: flex;
              margin-left: 6px;
              &:hover {
                path {
                  fill: $main-color;
                }
                line {
                  stroke: $main-color;
                }
              }
            }
          }
        }
      }
    }
  }

  .box-fixed-bottom {
    // opacity: 0;
    // visibility: hidden;
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 301;
    .dropdown {
      position: absolute !important;
      bottom: calc(100% + 7px);
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 3px;
      width: 132px;
      z-index: 102;
      ul {
        padding: 6px 3px;
        li {
          line-height: 27px;
          padding-left: 28px;
          position: relative;
          &.active {
            background-color: $background-active;
            &::before {
              position: absolute;
              z-index: 10;
              left: 8px;
            }
          }
          a {
            font-size: 14px;
            color: $text-main;
          }
        }
      }
    }
    &.round ul {
      border-radius: 100px;
    }
    > ul {
      display: flex;
      border: 1px solid #cccccc;
      border-radius: 3px;
      background: #fff;
      padding: 7px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      align-items: center;
      > li {
        margin: 0 8px;
        position: relative;
        &:first-child a:hover path {
          stroke: $main-color;
          fill: none;
        }
        &:nth-child(4) {
          margin-right: -5px;
          line-height: 10px;
        }
        > a {
          display: flex;
          &:hover {
            path {
              fill: $main-color;
            }
          }
        }
        & > .add-todo {
          width: 53px;
          height: 25px;
          border: 1px solid $main-color;
          color: $main-color;
          line-height: 23px;
          text-align: center;
          font-size: 13px;
          padding: 0;
        }
        & > .fixed-bottom-button {
          border: none;
          background-color: transparent;
          outline: none;
          padding: 0;
          line-height: 0;
        }
        .dropdown {
          position: absolute;
          bottom: calc(100% + 7px);
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 3px;
          width: 132px;
          z-index: 1;
          ul {
            padding: 6px 3px;
            li {
              line-height: 27px;
              padding-left: 28px;
              position: relative;
              &.active {
                background-color: $background-active;
                &::before {
                  // content: url("../images/check.png");
                  position: absolute;
                  z-index: 10;
                  left: 8px;
                }
              }
              a {
                font-size: 14px;
                color: $text-main;
              }
            }
          }
        }
      }
    }
  }

  .modal {
    z-index: 1000;
    &.edit-project {
      .modal-inner {
        padding-bottom: 40px;
        .ant-form-item-label
          > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          display: none;
        }
      }
      h3 {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        color: #3cb371;
        margin-bottom: 20px;
      }
      .form-group {
        + .form-group {
          margin-top: 17px;
        }
        label {
          color: $text-main;
          margin-bottom: 10px;
        }
        .w-219 {
          width: 219px;
          @include br739 {
            width: 100%;
          }
        }
        .w-206 {
          width: 206px;
          @include br739 {
            width: 100%;
          }
        }
        input {
          color: $line;
        }
      }
      .btn {
        margin: 0 auto;
        display: block;
        @include br739 {
          width: 100%;
        }
      }
      .btn-delete-project {
        width: 180px;
        height: 30px;
        background-color: #f86767;
        color: #fff;
        font-size: 14px;
        border: 1px solid #f86767;
        margin-top: 12px;
      }
      .project-warning {
        margin: 20px 0;
        color: #F86767;
      }
      .btn-small {
        margin-top: -3px;
      }
      .w-219 {
        width: 219px;
      }
      .w-197 {
        width: 197px;
      }
      .w-206 {
        width: 206px;
      }
      .w-480 {
        width: 480px;
      }
      .h-38 {
        height: 38px;
      }
      .transition-rate {
        & .ant-form-item-control-input-content {
          display: flex;
          align-items: flex-end;

          & .ant-select-selector {
            text-align: center;
          }

          & > .ant-form-item {
            margin-bottom: -5px;

            &.ant-form-item-has-error {
              position: relative;
              margin-bottom: 20px;

              & + span {
                margin-bottom: 30px;
              }

              & .ant-form-item-explain {
                position: absolute;
                bottom: -40px;
              }

              & ~ .has-error {
                margin-bottom: 20px;
              }
            }

            & input, & .ant-select-selection-search, & .ant-select-selector {
              height: 38px;
            }
          }

          & > span {
            display: inline-block;
            margin: 0 8px 5px;
            font-size: 14px;
          }

        }
      }
      & .ant-row:nth-child(3) {
        margin-top: -8px;

        & input, & .ant-select-selection-search, & .ant-select-selector {
          height: 38px;
        }
      }
    }
    &.about-setting {
      .modal-body {
        width: 720px;
      }
      .modal-inner {
        max-height: 75vh;
      }
      h3 {
        font-size: 16px;
        font-weight: 700;
        color: $text-main;
        margin-bottom: 21px;
      }
      .tabs {
        .nav-tabs {
          // display: flex;
          flex-wrap: wrap;
          // border-bottom: 1px solid #ccc;
          li {
            padding: 0 11px 3px;
            position: relative;
            &.active {
              &::after {
                content: '';
                width: 100%;
                height: 1px;
                background-color: $main-color;
                position: absolute;
                bottom: -1px;
                left: 0;
                z-index: 1;
              }
              a {
                color: $main-color;
              }
            }
            a {
              font-size: 16px;
              font-weight: 700;
              color: $text-disable;
            }
          }
        }
        .tab-content {
          & .tab-content-item {
            & > h3 {
              font-weight: 700;
              font-size: 16px;
              color: $main-color;
              margin-bottom: 16px;
              margin-top: 16px;
            }

            .youtube-wrapper {
              position: relative;
              padding-bottom: 52.25%;

              // padding-bottom: 56.25%;
              /* 16:9 */
              padding-top: 25px;
              height: 0;
            }

            .youtube-wrapper iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 1000 !important;
            }

            & .w-100 {
              width: 100%;
              height: 100%;

              &.template-youtube {
                min-height: 330px;
              }
            }

            & .template-image {
              display: flex;
              align-items: flex-end;

              & .btn-remove-image {
                border: 1px solid $alert;
                color: $alert;
                margin-left: 10px;
                width: 75px;
                height: 38px;
                font-size: 13px;
              }
            }
          }
          .btn-add-template {
            margin-top: 20px;
            width: 107px;
            height: 30px;
            color: #fff;
            background: $main-color;
            border: 1px solid $main-color;
          }
          .btn-add-image {
            width: 92px;
            height: 32px;
            border: 1px solid $line;
            background: $line;
            color: #fff;
            display: flex;
            align-items: center;

            span {
              margin-left: 11px;
            }
          }
          .note {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            .avatar {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              object-fit: cover;
            }
            .name {
              font-size: 14px;
              font-weight: 700;
              color: $text-main;
              margin-left: 8px;
            }
            .time {
              font-size: 14px;
              margin-left: 15px;
              color: $text-disable;
            }
            .delete {
              display: flex;
              margin-left: auto;
              margin-right: 8px;
              border: none;
              background-color: #fff;
              &:hover {
                path {
                  fill: $main-color;
                }
              }
            }
          }
          .tab-1 {
            width: 100%;
            p {
              font-size: 14px;
              color: $text-main;
              line-height: 22.4px;
              margin-bottom: 8px;
              word-wrap: break-word;
            }
            .image {
              margin-bottom: 12px;
            }
            textarea {
              height: 60px;
              padding: 12px 13px;
              font-size: 14px;
              color: #1b1b1b;
              // ::placeholder {
              //   color: #cccc !important;
              // }
            }

            .btn-image {
              margin-top: 4px;
              margin-bottom: 16px;
              &.custom-upload {
                .ant-upload-list-item-thumbnail {
                  pointer-events: none;
                }
              }
              button {
                background: #ccc;
                display: inline-flex;
                color: #fff;
                font-size: 14px;
                font-weight: 400;
                align-items: center;
                border-radius: 3px;
                border: 0;
                outline: none;
                padding: 0;
                cursor: pointer;
              }
            }
            .btn-post {
              text-align: center;
              a {
                @include br739 {
                  width: 100%;
                }
                color: #fff;
              }
            }
            .project-ticket {
              background: #fff;
              border: 1px solid $line;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;

              span {
                width: 12px;
                height: 9.5px;
                color: $line;
              }

              &:hover {
                border-color: $main-color;

                span {
                  color: $main-color;
                }
              }
            }
            .group-btn {
              margin-left: auto;
              display: flex;
              align-items: center;
            }
            .delete {
              margin-right: 13px;
              height: auto;
              padding: 0;
              color: $text-disable;
              margin-left: 0;
            }
            .btn-add-image {
              width: 92px;
              height: 32px;
              border: 1px solid $line;
              background: $line;
              color: #fff;
              display: flex;
              align-items: center;
              margin-top: 8px;

              span {
                margin-left: 11px;
              }
            }
            .comment-bottom {
              margin-top: 16px;
              & .ant-form-item-control-input-content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-flow: column wrap;

                & .ant-checkbox-wrapper {
                  & .ant-checkbox {
                    & .ant-checkbox-inner {
                      border-color: $main-color;
                    }
                  }
                  & > span:last-child {
                    color: $main-color;
                    font-weight: 400;
                    font-size: 16px;
                  }
                }

                button {
                  width: 107px;
                  height: 30px;
                  color: #fff;
                  background: $main-color;
                  margin-top: 16px;
                }
              }
            }
            .project-image {
              margin-top: 16px;

              &
                > .ant-form-item-control
                > .ant-form-item-control-input
                > .ant-form-item-control-input-content {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: flex-end;

                & .ant-avatar {
                  width: 100%;
                  height: 100%;
                }

                & button {
                  border: 1px solid $alert;
                  color: $alert;
                  margin-left: 10px;
                }
              }
            }
          }
          .tab-3 {
            h3 {
              font-size: 16px;
              font-weight: 700;
              color: $main-color;
              margin-bottom: 18px;
              .see-more {
                font-size: 14px;
                font-weight: 400;
                display: inline-flex;
                align-items: center;
                margin-left: 9px;
                svg {
                  margin-left: 7px;
                }
              }
            }
            .message {
              margin-top: 3px;
              margin-bottom: 18px;
              p {
                font-size: 14px;
                line-height: 22.4px;
                color: $text-main;
              }
            }
            .video {
              margin-bottom: 16px;
              .w-100 {
                height: 35vh;
              }
            }
          }
        }
      }
    }
    &.video-only {
      .modal-body {
        width: 720px;
        .modal-inner {
          padding: 0;
        }
      }
    }
    .modal-inner {
      padding-bottom: 40px;
      @include br739 {
        padding: 25px;
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: $line;
      }
    }
    .alert {
      color: $alert;
      font-size: 14px;
      margin-top: 18px;
      margin-bottom: 20px;
    }
    .form-group.error {
      input {
        border-color: $alert;
      }
    }
  }

  .block-column {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $line;
      border-radius: 8px;
    }
    .column {
      padding-bottom: 100px;
      cursor: pointer;

      &-focus {
        border: 1px solid #3CB371 !important;
      }
    }
  }
}

.active-connector {
  z-index: 100 !important;
}

.mindmap-textarea:disabled {
  color: unset !important;
  background-color: unset !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
  pointer-events:none;
}
