// Noto Sans JP
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

// Noto Sans 
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&display=swap');

// Spartan bold, Lato bold
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Spartan:wght@700&display=swap');

// Roboto 
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap');