.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 54px;
  z-index: 10;
  background-color: #fff;
  padding: 11px 0;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  .row {
    align-items: center;
  }
  .col-left {
    display: flex;
    align-items: center;
    .menu-mobile {
      display: none;
      padding-top: 5px;
      @include br1079 {
        display: flex;
      }
    }
    .title {
      margin-left: 10px;
      font-size: 16px;
      color: #1b1b1b;
      font-weight: 700;
      padding-top: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .col-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include br1079 {
      display: none;
    }
    .user-icon {
      display: inline-flex;
      margin-left: 4px;
    }
    .btn-user {
      line-height: 30px;
      height: 30px;
      padding: 0 23px;
      margin-right: 9px;
      margin-left: 24px;
      font-size: 14px;
      color: #fff;
      border: 1px solid $main-color;
    }
  }
}
