@for $i from 1 through 100 {
    // margin
    .mb-#{$i}{
        margin-bottom: #{$i}px;
    }
    .mt-#{$i}{
        margin-top: #{$i}px;
    }
    .ml-#{$i}{
        margin-left: #{$i}px;
    }
    .mr-#{$i}{
        margin-right: #{$i}px;
    }

    // padding
    .pb-#{$i}{
        padding-bottom: #{$i}px;
    }
    .pt-#{$i}{
        padding-top: #{$i}px;
    }
    .pl-#{$i}{
        padding-left: #{$i}px;
    }
    .pr-#{$i}{
        padding-right: #{$i}px;
    }
}