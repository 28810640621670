.open-sidebar{
    .wrapper{
        margin-left: 200px;
        @include br1079{
            margin-left: 0;
        }
    }
    .main-content{
        padding-left: 40px;
        @include br1079{
            padding-left: 15px;
        }
    }
  }
  .wrapper{
    transition: .2s all;
    margin-left: 50px;
    @include br1079{
      margin-left: 0px;
  }
  }
  .main-content{
    // width: 1200px;
    max-width: 100%;
    margin-top: 55px;
    padding: 24px 40px;
    padding-left: 86px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5px !important;
    // overflow-x: hidden;
    @include br1079{
        padding-left: 15px;
        padding-right: 15px;
    }
  }
  #task-board{
    .taskboard-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > p {
            border: none;
            outline: none;
            background: transparent;
            text-align: left;
            cursor: pointer;
            &:first-child {
                font-weight: 700;
                font-size: 13px;
                font-family: 'Noto Sans JP';
                // width: 100px;
                // overflow: hidden;
                // white-space: nowrap; 
                // text-overflow: ellipsis;
                word-break: break-word;
            }
            &:nth-child(2) {
                display: inline-block;
                min-width: 100px;
                max-width: 100%;
                padding: 0 12px;
                height: 19px;
                line-height: 19px;
                text-align: center;
                color: #fff;
                background-color: $main-color;
                border-radius: 43px;
            }
        }
    }
    .main-content{
        .title{
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 25px;
            color: $text-main;
            font-weight: bold;
        }
        .filter-form{
            form{
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
            }
            label{
                margin-bottom: 10px;
                color: $text-main;
                font-weight: 700;
            }
            .form-group{
                margin-right: 16px;
                margin-bottom: 15px;
                &.date{
                    select{
                        width: 129px;
                      //   padding: 0 11px;
                        appearance: none;
                        background: #fff url("../../../assets/images/calendar.png") no-repeat calc(100% - 11px) 10px;
                    }
                    span{
                      //   margin: 0 -3px 0 -1px;
                        color: $text-disable;
                    }
                }
                &.kpi{
                    .task-title{
                        width: 151px;
                    }
                    & .taskboard-member {
                        position: relative;
                        &::before {
                            content: "\003c"; 
                            // position: absolute;
                            // top: 50%;
                            // transform: translateY(-50%) rotate(45deg);
                            // right: -10px;
                            width: 30px;
                            height: 38px;
                            display: inline-block;
                            background-color: $line;
                            border: 1px solid $line;
                        }
                    } 
                }
            }
            .group-btn{
                margin-bottom: 15px;
                .btn{
                    padding: 0px 23px 0px;
                    border-radius: 3px;
                    font-weight: 400;
                    line-height: 34px;
                    height: 34px;
                    margin-right: 12px;
                    color: #fff;
                }
                .btn-clear{
                    background-color: $line;
                    border-color: $line;
                    color: #fff;
                }
            }
        }
        .block-column{
            margin-top: 11px;
            overflow-x: auto;
            // height: 65vh;
            // overflow-y: hidden;
            .row{
                margin-left: -3px;
                margin-right: -3px;
                width: 1166px;
                display: flex;
                flex-wrap: nowrap;
                .col{
                    flex: 0 0 25%;
                    max-width: 25%;
                    padding-left: 3px;
                    padding-right: 3px;
                    .status{
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        color: $text-main;
                        margin-bottom: 10px;
                        .number{
                            color: $text-disable;
                            margin-left: 7px;
                        }
                        .circle{
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            margin-right: 8px;
                            &.red{
                                background-color: $alert;
                            }
                            &.blue{
                                background-color: $blue;
                            }
                            &.disable{
                                background-color: $text-disable;
                            }
                            &.orange{
                                background-color: $orange;
                            }
                        }
                    }
                    .box{
                        background-color: #fff;
                        padding: 18px 17px;
                        margin-bottom: 5px;
                        // height: 100%;
                        height: 62vh;
                        overflow-x: hidden;
                        overflow-y: auto;
                        .add-task{
                            font-size: 14px;
                            font-weight: 700;
                            color: $main-color;
                            display: flex;
                            align-items: center;
                            margin-bottom: 11px;
                            svg{
                                margin-right: 8px;
                            }
                        }
                        .item{
                            border: 1px solid #CCCCCC;
                            padding: 18px 15px 17px;
                            border-radius: 3px;
                            position: relative;
                            margin-bottom: 10px;
                            background-color: #fff;
                            &:hover{
                                background-color: $background-active;
                            }
                           
                            h5{
                                width: 70%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-size: 13px;
                                font-weight: 700;
                                color: $text-main;
                                display: block;
                                max-width: 153px;
                                margin-bottom: 13px;
                                padding-right: 20px;
                            }
                            p{
                                font-size: 13px;
                                line-height: 1.55;
                                color: $text-main;
                                margin-bottom: 12px;
                                word-wrap: break-word;
                                // overflow: hidden;
                                // text-overflow: ellipsis;
                                // display: -webkit-box;
                                // -webkit-line-clamp: 2;
                                // -webkit-box-orient: vertical;
                            }
                            .date{
                                font-size: 12px;
                                color: $text-disable;
                                letter-spacing: initial;
                            }
                        }
                        &::-webkit-scrollbar {
                            width: 7px;
                        }
                        &::-webkit-scrollbar-track {
                            display: none;
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: $line;
                            border-radius: 8px;
                        }
                    }
                }
            }
            &::-webkit-scrollbar {
                height: 8px;
            }
            &::-webkit-scrollbar-track {
                display: none;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $line;
                border-radius: 8px;
            }
        }
    }
    .taskboard-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .taskboard-project {
            font-size: 12px;
            display: inline-block;
                min-width: 50px;
                max-width: 30%;
                padding: 0 10px;
                height: 19px;
                line-height: 19px;
                text-align: center;
                color: #fff;
                background-color: $main-color;
                border-radius: 43px;
                border: none;
            outline: none;
            cursor: pointer;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
  }