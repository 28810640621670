#task-list {
  .main-content {
    .title {
      font-size: 20px;
      margin-bottom: 25px;
      font-weight: bold;
    }
    .filter-form {
      form {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
      }
      label {
        margin-bottom: 10px;
      }
      .form-group {
        margin-right: 16px;
        margin-bottom: 15px;
        &.date {
          select {
            appearance: none;
            background: #fff url('../../../assets/images/down-3.png') no-repeat calc(100% - 11px)
              10px;

            &.by {
              width: 121px;
            }
            &.order {
              width: 77px;
            }
          }
          span {
            margin: 0 5px;
          }
        }
        &.status {
          select {
            width: 202px;
            appearance: none;
            background: #fff url('../../../assets/images/down-3.png') no-repeat calc(100% - 11px)
              10px;
          }
        }
      }
      .group-btn {
        margin-bottom: 15px;
        .btn {
          padding: 0px 23px 0px;
          border-radius: 3px;
          font-weight: 400;
          line-height: 34px;
          height: 34px;
          margin-right: 12px;
          color: #fff;
        }
        .btn-clear {
          background-color: $line;
          border-color: $line;
          color: #fff;
        }
      }
    }
  }
  .table {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    max-height: 78vh;
    z-index: 1;
    margin: auto;
    overflow-y: scroll;
    height: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $line;
      border-radius: 8px;
    }
    //   padding-bottom: 50px;

    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      background-color: #fff;
      border-radius: 8px;
      //   display: block;
      td,
      th {
        padding: 8px;
        //   border-radius: 8px;
        white-space: nowrap;
        &:nth-child(1) {
          padding-left: 20px;
        }
        &:nth-child(8) {
          padding-right: 32px;
        }
      }
      thead {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 8;
        width: 100%;
        // border-bottom: 1px solid $line;
      }
      thead::before {
        content: '';
        width: 100%;
        position: absolute;
        height: 1px;
        background-color: #ccc;
        z-index: 9;
        left: 0;
        top: 52px;
      }
      th {
        font-size: 14px;
        font-weight: 700;
        color: $text-accent;
        height: 52px;
        &.start-date {
          width: 15%;
        }
        &.end-date {
          width: 15%;
        }
        &.task-name {
          width: 10%;
        }
        &.explanation {
          width: 20%;
        }
        &.manager {
          width: 10%;
        }
        &.status {
          width: 10%;
        }
        &.project {
          width: 10%;
        }
        &.action {
          width: 10%;
          button {
            outline: none;
            border: none;
            background: transparent;
            cursor: pointer;
          }
        }
      }
      tbody {
      }
      tfoot {
      }
      td {
        font-size: 14px;
        color: $text-main;
        height: 56px;
        position: relative;
        &.start-date {
          width: 15%;
        }
        &.end-date {
          width: 15%;
        }
        &.task-name {
          width: 10%;
          p {
            width: 195px;
            font-weight: 700;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &.explanation {
          //   &:hover{
          //       .full{
          //           opacity: 1;
          //           visibility: visible;
          //       }
          //   }
          width: 20%;
          p {
            width: 195px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .full {
            word-wrap: break-word;
            //   opacity: 0;
            //   visibility: hidden;
            white-space: initial;
            -webkit-transition: 0.2s all;
            transition: 0.2s all;
            position: absolute;
            top: 68px;
            z-index: 5;
            left: 50%;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 3px;
            padding: 3px;
            width: 224px;
            line-height: 22.4px;
            transform: translateX(-50%);
          }
        }
        &.manager {
          width: 10%;
          span {
            font-size: 14px;
            font-weight: 700;
            color: #1b1b1b;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              margin-right: 8px;
            }
          }
        }
        &.status {
          width: 10%;
          label {
            background: $alert;
            border-radius: 100px;
            color: #fff;
            font-weight: 400;
            display: inline-block;
            padding: 3px 21px;
            margin-bottom: 0;
          }
        }
        &.action {
          width: 10%;
          button {
            outline: none;
            border: none;
            background: transparent;
            cursor: pointer;
          }
        }
        svg:hover {
          fill: $main-color;
        }
      }
      tr {
        border-bottom: 1px solid #ccc;
        //   &:nth-child(1) {
        //       background-color: $background-active;
        //   }
        &.task-active {
          background-color: $background-active;
        }
        &:hover {
          background-color: $background-active;
        }
        &:nth-child(1) {
          border-top: 0;
        }
      }
    }
    .group-add {
      background-color: $background-active;
      input,
      select {
        height: 30px;
      }
      select {
        appearance: none;
        background: #fff url('../../../assets/images/down-3.png') no-repeat calc(100% - 5px) 7px;
      }
      .keep {
        color: $main-color;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
      }
      .save, .cancel {
        color: $main-color;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
    .w-98 {
      width: 98px;
    }
    .w-128 {
      width: 128px;
    }
    .w-208 {
      width: 208px;
    }
    .w-126 {
      width: 126px;
    }
    .w-200 {
      width: 200px;
    }
    .w-102 {
      width: 102px;
    }
    .w-104 {
      width: 104px;
    }
    .add-task {
      font-size: 14px;
      font-weight: 700;
      color: $main-color;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      padding: 0 46px;
      button {
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        outline: none;
        cursor: pointer;
        font-weight: 700;
      }
      svg {
        margin-right: 8px;
      }
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 3;
      width: 100%;
      height: 10vh;
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $line;
      border-radius: 8px;
    }
  }
}
.modal {
  &.delete-task {
    .modal-body {
      padding: 43px 40px 48px;
      width: 560px;
      max-width: calc(100% - 30px);
      top: 50%;
      background-color: #fff;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      border-radius: 3px;
      z-index: 9;
      h3 {
        font-size: 16px;
        margin-bottom: 26px;
        color: $text-main;
        font-weight: bold;
        word-break: break-word;
      }
      .btn-group {
        .btn {
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
          height: 30px;
          padding: 0 10px;
          margin: 0 6px;
          color: #fff;
        }
        .btn-alert {
          background-color: $alert;
          border-color: $alert;
          width: 77px;
          font-weight: 400 !important;
          padding: 0;
        }
        .btn-cancel {
          background-color: $line;
          border-color: $line;
          width: 121px;
          font-weight: 400 !important;
          padding: 0;
        }
      }
    }
  }
}
