#invite-user {
  .container {
    max-width: 808px;
  }
  .wrapper {
    padding: 82px 0;
    margin-left: 0;
    h2.title {
      font-size: 20px;
      font-weight: 700;
      color: $text-main;
      margin-bottom: 24px;
    }
    .desc {
      font-size: 14px;
      color: $text-main;
      margin-bottom: 16px;
    }
    .error-msg {
      color: $alert;
    }
    form {
      .form-groups {
        display: flex;
        align-items: flex-end;
        .form-group {
          margin-right: 16px;
        }
      }
      .form-group {
        margin-bottom: 18px;
        label {
          margin-bottom: 8px;
          color: #333333;
        }
        input,
        select,
        textarea {
          background: #fff;
          border: 1px solid $text-disable;
          border-radius: 3px;
          height: 38px;
          line-height: 38px;
          padding: 0 13px;
          font-size: 14px;
          color: $line;
          letter-spacing: 0.05em;
          max-width: 100%;
          overflow: hidden;
          &::placeholder {
            color: $line;
          }
          &.w-496 {
            width: 496px;
          }
        }
        input,
        textarea {
          color: $text-main;
          &:hover,
          &:focus {
            box-shadow: none;
          }
        }
        select {
          appearance: none;
          width: 77px;
          padding: 0 11px;
        }
        .ant-input-status-error {
          border-color: $alert;
        }
      }
      .btn-add-kpi {
        font-size: 13px;
        background: transparent;
        color: $main-color;
        line-height: 29px;
        height: 29px;
        padding: 0 23px;
        font-weight: 400;
        margin-top: -2px;
        border-radius: 3px;
      }
      .group-bottom {
        text-align: center;
        margin-top: -3px;
        display: flex;
        justify-content: center;
        @include br739 {
          flex-direction: column;
        }
        .btn {
          font-size: 16px;
          margin-bottom: 20px;
          line-height: 34px;
          height: 34px;
          padding: 0 23px;
          border-radius: 3px;
          margin: 0 10px;
          color: #fff;
          border: 1px solid $main-color;
          @include br739 {
            margin: 0 0 15px;
          }
          &.btn-return {
            background-color: $line;
            border-color: $line;
            font-weight: 400 !important;
            &:hover {
              color: #ffffff;
            }
          }
        }
      }

      & > .form-members {
        & .ant-form-item-control-input-content {
          display: flex;
          align-items: flex-end;

          & > .form-group {
            &.form-email {
              margin-right: 16px !important;

              & .ant-form-item-has-error {
                & input {
                  border-color: $alert;
                }
              }

              & input {
                border-color: $line;
              }
            }
            &.form-rank {
              & .ant-select {
                width: 256px;
                height: 38px;

                &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                  height: 38px !important;
                }
              }
            }
            & .ant-form-item-control-input-content {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              position: relative;
              padding-bottom: 5px;

              & .ant-form-item-has-error {
                & .ant-form-item-explain {
                  position: absolute;
                  bottom: -20px;
                }
              }
            }
          }
        }
      }

      & .btn-add-member {
        width: 184px;
        height: 29px;
        border: 1px solid $main-color;
        color: $main-color;
        background-color: #fff;
        font-weight: 400 !important;
        margin-bottom: 20px;
      }

      & .btn-remove-member {
        border: 1px solid $alert;
        color: $alert;
        background-color: #fff;
        height: 38px;
        margin-left: 15px;
        font-weight: 400 !important;
        margin-bottom: 28px;
      }
    }
  }
}
