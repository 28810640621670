@charset "UTF-8";
::placeholder {
    color: #1B1B1B;
  }
/***** mixin *****/
@import 'mixin/variables';
@import 'mixin/mediaquery';
@import 'mixin/space';

/***** base *****/
@import 'base/media';
@import 'base/base';
@import 'base/header';
@import 'base/nav-sidebar';

/***** fonts *****/
@import 'fonts/fonts';

/***** pages *****/
@import 'pages/authentication';
@import 'pages/cockpit-list';
@import 'pages/cockpit-creation';

@import 'pages/project-details';
@import 'pages/task';
@import 'pages/dashboard-creation';
@import 'pages/dashboard-setting';
@import 'pages/task-board';
@import 'pages/task-list';
@import 'pages/invite-user';
@import 'pages/dashboard';
@import 'pages/notification';
@import 'pages/my-page';
@import 'pages/campaign-management';

@import 'mixin/override-antd';
@import 'common/index.scss';

.ant-message {
  z-index: 20000 !important;
}
