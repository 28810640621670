#notification{
    & .main-content {
        margin-top: 0;

        & > .ant-pagination {
            & > .ant-pagination-prev {
                display: none;
            }
        }
    }
    .page-title{
        font-size: 16px;
        color: $main-color;
        margin-bottom: 24px;
        font-weight: 700;
    }
    & .notification-type {
        width: 160px;
        height: 38px;
        margin-bottom: 16px;

        & .ant-select-selection-item {
            color: $text-main;
            font-weight: 400;
            font-size: 14px;
        }
    }
    .message{
        border-top: 1px solid $line;
        padding-left: 0;
        li{
            padding: 16px 15px 14px 24px;
            border-bottom: 1px solid $line;
            background-color: #fff;
            position: relative;
            &:hover{
                background-color: $background-active;
            }
            @include br739{
                padding-top: 39px;
                padding-left: 27px;
            }
        }
        .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
            @include br739{
                margin-bottom: 4px;
            }
            .title{
                display: flex;
                align-items: center;
                .status{
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin-right: 7px;
                    position: relative;
                    // bottom: 4px;
                    @include br739{
                        position: absolute;
                        left: 8px;
                        top: 20px;
                    }
                    &.active{
                        background-color: $main-color;
                    }
                }
                h3{
                    font-size: 14px;
                    font-weight: 700;
                    margin-left: 8px;
                    margin-bottom: 0px;
                    max-width: 950px;

                    b {
                        word-break: break-all;
                        display: inline-block;
                        overflow-wrap: break-word;
                        -ms-word-break: break-word;
                        word-break: break-word;
                    }
                }
            }
            .time{
                font-size: 14px;
                color: $text-disable;
                @include br739{
                    position: absolute;
                    top: 18px;
                    left: 27px;
                    font-size: 12px;
                }
            }
        }
        p{
            font-size: 14px;
            color: $text-main;
            line-height: 22.4px;
            letter-spacing: 0.05em;
            padding: 0 20px;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            @include br739{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0;
            }
        }
    }
}