.center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    &.textarea-height {
        height: auto;
    }
}

.cursor-pointer {
    cursor: pointer;
}