#campaign-management {
  padding-top: 24px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    @include br739 {
      flex-direction: column;
      align-items: flex-start;
      .page-title {
        margin-bottom: 15px;
      }
    }
    .btn {
      height: 34px;
      line-height: 32px;
      font-size: 16px;
      color: #fff;
      border: 1px solid $main-color;
    }
  }
  .page-title {
    font-size: 20px;
    font-weight: 700;
    color: $text-main;
    margin-bottom: 0;
  }
  .filter-date {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 5px;
    @include br739 {
      flex-direction: column;
      align-items: baseline;
    }
    .form-group {
      margin-right: 16px;
      margin-bottom: 15px;
      @include br739 {
        margin-right: 0;
        width: 100%;
      }
      label {
        margin-bottom: 10px;
        color: $text-main;
      }
      .dropdown {
        @include br739 {
          width: calc(50% - 11px);
          justify-content: space-between;
        }
      }
      .select-custom {
        background: #ffffff;
        border: 1px solid $line;
        border-radius: 3px;
        font-size: 14px;
        padding: 0 10px;
        display: inline-flex;
        justify-content: space-between;
        line-height: 36px;
        align-items: center;
        @include br739 {
          width: 100%;
        }
        + .dropdown-menu {
          top: calc(100% + 8px);
          border: 1px solid #cccccc;
          border-radius: 3px;
          width: 280px;
          background: #fff;
          z-index: 10;
          .title {
            display: flex;
            justify-content: center;
            margin-top: 9px;
            margin-bottom: 2px;
            .nav {
              display: flex;
              align-items: center;
              a {
                display: flex;
              }
              span {
                font-size: 14px;
                color: $text-main;
                margin: 0 9px;
              }
            }
          }
          .table-calendar {
            text-align: center;
            overflow: hidden;
            table {
              width: calc(100% + 2px);
              margin: 0 -1px -1px;
              border-collapse: collapse;
              tr {
                th {
                  font-size: 12px;
                  color: $text-disable;
                  font-weight: 500;
                  padding: 11.7px 0;
                }
                td {
                  font-size: 12px;
                  color: $text-main;
                  border: 1px solid $line;
                  padding: 11.7px 0;
                  cursor: pointer;
                  transition: 0.2s all;
                  &.selected,
                  &:hover {
                    background-color: $main-color;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        svg {
          margin-left: 10px;
        }
      }
      span {
        font-size: 14px;
        margin-right: 4px;
        margin-left: 4px;
        color: $text-disable;
      }
      select {
        margin-right: 4px;
        @include br739 {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .btn-display {
        margin-left: 7px;
        position: relative;
        top: 5px;
        padding: 0 26px;
        @include br739 {
          display: none;
        }
      }
      input {
        width: 100%;
        color: $text-main;
        border: 1px solid $line;

        &:hover,
        &:focus {
          border: 1px solid $text-disable;
          box-shadow: none;
        }
        &::placeholder {
          font-family: 'Noto Sans JP';
        }
      }
      & .ant-form-item-control-input-content {
        display: flex;
      }

      .ant-select {
        width: 86px;
      }

      .ant-picker {
        width: 129px;
        height: 38px;
        border: 1px solid $line;
        input {
          border: none;
        }
        &-focused,
        &:hover {
          border-color: #808080;
          box-shadow: none;
          border-radius: 3px;
        }
      }
    }
    .group-btn {
      margin-left: auto;
      margin-bottom: 15px;
      @include br739 {
        display: none;
      }
      a {
        background-color: #fff;
        border-color: $text-disable;
        color: $text-disable;
      }
    }
  }
  .main-content {
    margin-top: 24px;
    .search {
      display: flex;
      justify-content: space-between;
      margin-top: 2px;
      margin-bottom: 22px;
      position: relative;
      @include br1079 {
        flex-direction: column;
      }
      .form-group {
        h3 {
          font-size: 13px;
          color: $text-main;
          font-weight: 700;
          margin-bottom: 9px;
        }
        .ant-input-affix-wrapper {
          width: 480px;
          input {
            width: 100%;
            padding: 12px;
            color: $text-main;
            &::placeholder {
              color: $line;
            }
          }
          .ant-input-suffix {
            margin-right: 12px;
          }
        }
      }
      .btn {
        height: 34px;
        line-height: 32px;
        font-size: 16px;
        color: #fff;
        border: 1px solid $main-color;
        @include br1079 {
          margin-top: 15px;
        }
      }
      .clear-text {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        top: 53%;
        right: 10px;
        border: 1px solid $main-color;
        transition: 0.2s all;
        cursor: pointer;
        &:hover {
          background-color: $main-color;
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  .tabs {
    ul {
      margin: 0;
      padding: 0;
    }
    .tabs-nav {
      display: flex;
      border-bottom: 1px solid $line;
      margin-bottom: 24px;
      margin-top: 26px;
      li {
        margin-right: 8px;
        padding: 3px 20px;
        position: relative;
        &.active {
          &::after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: -1px;
            left: 0;
            background-color: $main-color;
            z-index: 1;
          }
          button {
            color: $main-color;
          }
        }
        button {
          font-size: 16px;
          font-weight: 700;
          color: $text-disable;
          border: 0;
          background-color: transparent;
          cursor: pointer;
        }
      }
    }
    .tabs-content {
      .row {
        margin-left: -12px;
        margin-right: -12px;
        > div {
          padding-left: 12px;
          padding-right: 12px;
          margin-bottom: 24px;
        }
        .col-xl-3 {
          -ms-flex: 0 0 25%;
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
      .item {
        border: 1px solid $line;
        border-radius: 8px;
        padding: 17px 15px;
        background-color: #fff;
        position: relative;
        &:hover {
          background-color: $background-active;
          .edit {
            opacity: 1;
            visibility: visible;
          }
        }
        .edit {
          opacity: 0;
          visibility: hidden;
          transition: 0.2s all;
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 1;
          border: 0;
          background-color: transparent;
          cursor: pointer;
        }
        ul {
          li {
            display: flex;
            + li {
              margin-top: 12px;
            }
            .name {
              min-width: 44px;
              margin-right: 15px;
              font-size: 14px;
              color: $text-disable;
              white-space: nowrap;
            }
            .value {
              font-size: 14px;
              font-weight: 700;
              color: $text-main;
              width: auto;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .chart {
    .row {
      margin-left: -10px;
      margin-right: -10px;
      > div {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
      }
    }
    .chart-box {
      padding: 24px 24px 21px 12px;
      background: #ffffff;
      border: 1px solid $line;
      border-radius: 8px;
      width: 100%;
      height: 348px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 16px;
        font-weight: 700;
        color: $main-color;
        margin-bottom: 19px;
      }
      g.recharts-layer .recharts-bar-rectangle {
        &:hover {
          & path {
            fill: #91CF87;
          }
        }
      }
    }
    & .recharts-tooltip-wrapper {
      height: 30px;
      border-radius: 15px;
      top: 0 !important;

      & .tooltip-infor {
        min-width: 67px;
        height: 100%;
        background: #fff;
        border: 1px solid #3cb371;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        position: relative;

        img {
          position: absolute;
          bottom: -11px;
        }
      }
    }
  }

  .table {
    border-radius: 8px;
    .ant-table {
      border: 1px solid #ccc;
      background-color: #fff;
    }
    .ant-pagination {
      & > .ant-pagination-prev {
        display: none;
      }
    }
    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      background-color: #fff;
      border-radius: 8px;
      .company-status {
        width: 74px;
        height: 23px;
        border-radius: 100px;
        border: 1px solid $text-disable;
        display: block;
        color: $text-disable;
        font-size: 13px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          background: $main-color;
          color: #fff;
          position: relative;
          border-color: $main-color;
          padding-left: 10px;

          &::before {
            position: absolute;
            display: table;
            border: 1px solid #fff;
            border-top: 0;
            border-right: 0;
            transform: rotate(-45deg) scale(1) translate(-50%, -50%);
            width: 9px;
            height: 6px;
            content: ' ';
            top: 21%;
            left: 15%;
          }
        }
      }
      td,
      th {
        padding: 8px;
        border-radius: 8px;
        white-space: nowrap;
        &:nth-child(1) {
          padding-left: 20px;
        }
        &:nth-child(8) {
          padding-right: 32px;
        }
      }
      th {
        font-size: 14px;
        font-weight: 700;
        color: $text-accent;
        height: 52px;
      }
      td {
        font-size: 14px;
        color: $text-main;
        height: 55px;
        position: relative;
        .label {
          border-radius: 100px;
          font-size: 13px;
          display: inline-block;
          line-height: 23px;
          padding: 0 8.5px;
          min-width: 74px;
          &.paid {
            background-color: $main-color;
            color: #fff;
          }
          &.unpaid {
            color: $text-disable;
            background-color: #fff;
            border: 1px solid $line;
          }
        }
        a:hover path {
          fill: $main-color;
        }
      }
      tr {
        border-bottom: 1px solid #ccc;
        &:nth-child(1) {
          border-top: 0;
        }

        &:hover {
          td {
            background-color: #F4FBF4;
          }
        }
      }
    }
    .ant-table-header {
      border-radius: 8px;
    }
    .ant-table-body {
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $line;
        border-radius: 8px;
      }
    }
    .group-add {
      input,
      select {
        height: 30px;
      }
      select {
        appearance: none;
      }
      .keep {
        color: $main-color;
      }
    }
    .w-98 {
      width: 98px;
    }
    .w-128 {
      width: 128px;
    }
    .w-208 {
      width: 208px;
    }
    .w-126 {
      width: 126px;
    }
    .w-102 {
      width: 102px;
    }
    .w-104 {
      width: 104px;
    }
  }
}

.company-modal {
  width: 560px !important;
  position: static !important;
  &.campaign-modal {
    width: 469px !important;

    .modal-body {
      .modal-inner {
        padding-bottom: 40px;

        .form-group {
          &:nth-last-child(2) {
            .ant-select,
            .ant-select-selector {
              width: 136px;
            }
          }
        }

        .ant-select,
        .ant-select-selector {
          height: 38px;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: 1px solid $line;
        }

        .form-date {
          & .ant-form-item-control-input-content {
            display: flex;
          }

          .ant-select {
            width: 86px;
          }

          .ant-picker {
            margin-right: 8px;
            width: 132px;
            height: 38px;
            input {
              border: none;
            }
          }
        }

        .ant-form-item-control-input {
          min-height: 38px;
          .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
          .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
            .ant-select-selector,
          .ant-picker-focused,
          .ant-picker:hover {
            border-color: #808080;
          }
          .ant-select:not(.ant-select-customize-input) .ant-select-selector,
          .ant-picker {
            border-radius: 3px;
          }
          .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
            .ant-select-selector,
          .ant-picker-focused {
            box-shadow: none;
          }
        }

        .btn-grey {
          margin-left: 0;
        }
      }
    }
  }
  &.registered {
    .modal-body {
      width: 375px;
      .modal-inner {
        padding: 43px 39px 48px;
        @include br739 {
          padding: 20px;
        }
      }
    }
    ul {
      padding-left: 0;
      li {
        display: flex;
        + li {
          margin-top: 20px;
        }
        .name {
          font-size: 14px;
          color: $text-disable;
          min-width: 45px;
          margin-right: 15px;
        }
        .value {
          font-size: 14px;
          font-weight: 700;
          color: $text-main;
        }
      }
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .modal-body {
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    .modal-inner {
      padding: 40px 40px 48px;
      max-height: 80vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $line;
        border-radius: 8px;
      }
    }
    .close-modal {
      // z-index: 100000000000000000000000000000000;
      width: 28px;
      height: 28px;
      display: flex;
      background: #fff;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -14px;
      right: -15px;
      border: 1px solid $main-color;
      transition: 0.2s all;
      cursor: pointer;
      &:hover {
        background-color: $main-color;
        path {
          fill: #fff;
        }
      }
    }
    h3 {
      font-size: 16px;
      font-weight: 700;
      color: $text-accent;
      margin-bottom: 16px;
    }
    .error-msg {
      font-size: 14px;
      margin: 16px 0;
      color: $alert;
    }
    .form-group {
      margin-bottom: 18px;
      label {
        margin-bottom: 10px;
        color: $text-main;
      }
    }
    .group-btn {
      text-align: center;
      margin-top: -3px;
      .btn {
        height: 34px;
        line-height: 32px;
        font-size: 16px;
        color: #fff;
        border: 1px solid $main-color;
      }
      .btn-grey {
        color: #fff;
        background-color: $line;
        border-color: $line;
        margin-left: 10px;
      }
    }
  }
}
