#dashboard-setting {
  color: $text-main;
  .ant-form-item-label > label {
    color: $text-main;
  }
  .ant-input {
    color: #333;
  }
  .main-content {
    color: $text-main;
    .menu-link-1 {
      margin-bottom: 10px;
      a {
        font-weight: bold;
        color: #2E8B57;
      }
    }
    .menu-link {
      margin-bottom: 20px;
      a {
        font-weight: bold;
        color: #2E8B57;
      }
    }
    .link-item {
      margin-left: 15px;
    }
    h3.title {
      font-size: 20px;
      color: $text-main;
      margin-top: 3px;
      margin-bottom: 20px;
      font-weight: 700;
    }
    h5 {
      font-size: 16px;
      color: $text-accent;
      margin-right: 7px;
      margin-bottom: 0;
      font-weight: 700;
    }
    .w-496 {
      width: 496px;
    }
    .notice {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 20px;
      h5 {
        margin-right: 7px;
        margin-bottom: 0;
      }
      p {
        font-size: 13px;
        color: $text-main;
        padding-top: 4px;
        margin-bottom: 0;
      }
    }

    .dashboard {
      label {
        color: $text-main;
      }
      .form-group {
        &.dashboard-name {
          margin-bottom: 18px;
          input {
            width: 496px;
          }
        }
        label {
          margin-bottom: 10px;
        }
        .dontshow {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          label {
            margin-bottom: 0;
            margin-left: 10px;
            font-weight: 400;
          }
        }
        .box {
          margin-top: 8px;
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 17px 15px 15px;
          display: block !important;
          .form-group {
            + .form-group {
              margin-top: 18px;
            }
            label {
              margin-bottom: 10px;
            }
            input {
              padding: 0 10px;
              &.w-496 {
                width: 496px;
              }
              &::placeholder {
                color: $line;
              }
            }
          }
        }
        .btn-border-main {
          background-color: #fff;
          @include br739 {
            width: 100%;
          }
        }
      }

      .notification-settings {
        label {
          margin-bottom: 0;
        }
        margin-top: 35px;
        .checkbox {
          + .checkbox {
            margin-top: 19px;
            label {
              margin-bottom: 0px !important;
              .ant-select {
                margin-left: 10px;
                margin-right: 10px;
              }
            }
          }
          label {
            margin-bottom: 0px !important;
          }
          select {
            margin: 0 5px;
            width: 65px;
            padding: 0 10px;
          }
        }
      }

      .task-status {
        margin-top: 37px;
        .form-group {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          + .form-group {
            margin-top: 8px;
          }
          label {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 10px;
          }
          .w-496 {
            width: 496px;
            @include br739 {
              width: calc(100% - 170px);
            }
          }
          input {
            margin-right: 12px;
            padding: 0 11px;
            margin-bottom: 10px;
          }
          .dropdown {
            position: relative;
            margin-right: 12px;
            margin-bottom: 10px;
            a {
              padding: 0 11px;
              border: 1px solid #cccccc;
              box-sizing: border-box;
              border-radius: 3px;
              width: 70px;
              display: inline-flex;
              height: 38px;
              align-items: center;
            }
            .circle {
              width: 16px;
              height: 16px;
              display: inline-block;
              border-radius: 50%;
              border: 1px solid #cccccc;
              &-big {
                width: 22px;
                height: 22px;
              }
              &.black {
                background: $text-main;
              }
              &.line {
                background: $line;
              }
              &.alert {
                background: $alert;
              }
              &.blue {
                background: $blue;
              }
              &.orange {
                background: $orange;
              }
            }
            &.open {
              .dropdown-menu {
                opacity: 1;
                visibility: visible;
              }
            }
            .dropdown-menu {
              position: absolute;
              background-color: #fff;
              left: 0;
              top: 100%;
              width: 60px;
              border: 1px solid #cccccc;
              border-radius: 3px;
              opacity: 0;
              visibility: hidden;
              z-index: 1;
              transition: 0.2s all;
              ul {
                padding: 6px 2px;
                li {
                  position: relative;
                  text-align: right;
                  padding: 0.5px 9px;
                  &.active,
                  &:hover {
                    background-color: $background-active;
                  }
                  svg {
                    position: absolute;
                    left: 10px;
                    top: 5px;
                  }
                }
              }
            }
          }
          .btn-border-alert {
            margin-bottom: 10px;
          }
        }
        .btn-border-main {
          background-color: #fff;
          @include br739 {
            width: 100%;
          }
        }
      }

      .remove-cockpit {
        margin: 35px 0 22px;
        .notice {
          margin-bottom: 18px;
          display: flex;
          align-items: flex-end;

          p {
            padding-top: 0;
            margin-bottom: 0;
          }
        }
        .group-btn {
          .btn-border-alert {
            border: 1px solid $alert;
            background-color: #fff;
            border-radius: 3px;
            line-height: 26px;
            font-size: 13px;
            width: 179px;
            color: $alert;
            height: 29px;
            font-weight: 400;
            margin-bottom: 10px;
            span {
              width: 100px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .edit-company {
        margin-top: 26px;
        .form-group {
          label {
            margin-bottom: 10px;
          }
          input {
            padding: 0 10px;
            &::placeholder {
              color: $line;
            }
          }
          + .form-group {
            margin-top: 18px;
          }
        }
        .btn-save {
          display: block;
          width: 81px;
          margin: 31px auto 0;
          padding: 5px 20px 5px;
          height: 34px;
          @include br739 {
            width: 100%;
          }
        }
      }

      .change-password {
        margin-top: 36px;
        h5 {
          margin-bottom: 18px;
        }
        .btn-border-main {
          background-color: #fff;
          @include br739 {
            width: 100%;
          }
        }
      }

      .plan-settings {
        margin-top: 35px;
        ul {
          li {
            display: flex;
            margin-bottom: 17px;
            @include br739 {
              flex-direction: column;
            }
            .name {
              min-width: 147px;
              font-size: 14px;
              font-weight: 700;
              margin-right: 15px;
            }
            .value {
              font-size: 14px;
              font-weight: 700;
              line-height: 1.55;
            }
          }
        }
      }

      .permission {
        margin-top: 35px;
        .form-group {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          + .form-group {
            margin-top: 6px;
          }
          label {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            margin-right: 45px;
            padding-top: 3px;
            margin-bottom: 10px;
            @include br739 {
              margin-right: 20px;
            }
            svg {
              margin-right: 8px;
            }
          }
          select {
            width: 210px;
            margin-right: 17px;
            margin-bottom: 10px;
            @include br739 {
              width: calc(100% - 212px);
            }
          }
          .btn-border-alert {
            margin-bottom: 10px;
          }
        }
        .btn-border-main {
          background-color: #fff;
          @include br739 {
            width: 100%;
          }
        }
      }
    }

    .kpi {
      margin-top: 35px;
      .ant-form-horizontal
        .ant-form-item
        .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
      .ant-form-horizontal
        .ant-form-item
        .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        border-color: $alert;
      }
      .btn-add-kpi {
        width: 111px;
        height: 29px;
        border: 1px solid $main-color;
        color: $main-color;
        background-color: #fff;
        line-height: 29px;
        border-radius: 3px;
        margin-top: 15px;
        font-weight: 400 !important;
        text-align: center;
        font-size: 13px;
      }
      .btn-save {
        display: block;
        text-align: center;
        color: #fff;
        background-color: $main-color;
        font-size: 16px;
      }
      .form-group {
        label {
          margin-bottom: 10px;
        }
        .date {
          height: 38px;
          margin-right: 8px;
          width: 82px;

          &.month {
            width: 65px;
            margin-left: 8px;
          }
        }
        .ant-select {
          color: $text-main;
        }
      }
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: none;
        border: 1px solid $text-disable;
      }
      & .form-wrapper {
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 16px;
        padding-bottom: 30px;

        & .group-btn-position {
          &.ml-15 {
            margin-left: 15px !important;
          }

          & .ant-form-item-control-input-content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            height: 38px;

            & .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
              border-color: $line !important;
              background: transparent;

              & .anticon {
                color: $line;
              }
            }

            & .ant-btn {
              margin-right: 0;
              height: auto;
              line-height: 100%;
              border-color: $main-color;

              &:hover {
                background: $main-color;

                & .anticon {
                  color: #fff;
                }
              }

              & .anticon {
                font-size: 12px;
                vertical-align: middle;
                color: $main-color;
              }
            }
          }
        }

        @media screen and (min-width: 1200px) {
          width: 100%;
        }

        & .unit {
          input {
            &::placeholder {
              font-size: 11px;
            }
            margin-left: 8px;
          }
          & .ant-form-item-explain-error {
            @media screen and (min-width: 1200px) {
              margin-left: -55px;
            }
          }
        }
      }
      .account {
        & h5 {
          margin-bottom: 16px;
          margin-top: 11px;
        }
        & p {
          font-size: 16px;
          font-weight: 400;
          color: $text-disable;

          & span {
            color: $text-main;
            font-size: 20px;
            font-weight: 700;
            margin-left: 8px;
          }
        }
      }
      .box {
        margin: 16px 0 15px;
        width: 100%;
        // .ant-form-item-explain {
        //   position: absolute;
        //   width: auto;
        //   white-space: normal;
        //   top: 100%;
        //   z-index: 9;

        //   .ant-form-item-explain-error {
        //     white-space: nowrap;
        //   }
        // }
        & > .form-item-wrapper {
          display: flex;
          flex-flow: column;
          & .form-wrapper:first-child {
            order: 1;
          }
        }
        .btn-remove-kpi {
          background-color: #fff;
          border: 1px solid $alert;
          color: $alert;
          width: 75px;
          height: 38px;
          cursor: pointer;
          margin-left: 8px;
          font-size: 13px;
          font-weight: 400;
        }
        &.box-2 {
          width: 364px;
          max-width: 100%;
          @include br739 {
            width: 100%;
          }
        }
        .form-groups {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          label {
            color: $text-main;
          }
          .form-group {
            margin-right: 16px;
            @include br739 {
              width: 100%;
              margin-right: 10px;
            }
            .ant-select-selector {
              width: 77px;
              height: 38px;
              border: 1px solid $line;
              margin-left: 16px;
            }
          }
        }
        .form-group {
          margin-bottom: 18px;
          &.kpi-name {
            input::placeholder {
              color: $line;
            }
          }
          label {
            margin-bottom: 10px;
          }
          input,
          select {
            background: #fff;
            border-color: $line;
            border-radius: 3px;
            height: 38px;
            padding: 0 13px;
            font-size: 14px;
            color: $text-main;
            letter-spacing: 0.05em;
            @include br739 {
              width: 100% !important;
            }
            &::placeholder {
              color: $line;
            }
            &.w-437 {
              width: 437px;
            }
            &.w-94 {
              width: 94px;
            }
            &.w-77 {
              width: 77px;
            }
            &.w-248 {
              width: 248px;
            }
            &.w-953 {
              width: 715px;

              @media screen and (min-width: 1281px) {
                width: 100%;
              }
            }
            &.w-1067 {
              width: 715px;

              @media screen and (min-width: 1280px) {
                width: 900px;
              }

              @media screen and (min-width: 1400px) {
                width: 1000px;
              }

              @media screen and (min-width: 1440px) {
                width: 1067px;
              }
            }
            &.w-53 {
              width: 53px;
            }
            &:focus,
            &:hover {
              box-shadow: none;
              border-color: $text-disable;
            }
          }
          select {
            padding: 0 11px;
          }
          & .manager-sub-kpi {
            max-width: 687px;
            margin-top: 20px;
            border: 1px solid $line;
            padding: 16px;
            border-radius: 8px;

            & .manager-sub-kpi-item {
              display: flex;
              align-items: flex-end;

              .member-name {
                display: inline-block;
                border: 1px solid $line;
                margin-right: 8px;
                width: 160px;
                height: 38px;
                line-height: 38px;
                padding-left: 12px;
                padding-right: 12px;
                border-radius: 3px;
                overflow: hidden;
                white-space: nowrap; 
                text-overflow: ellipsis;
              }

              & > .ant-row {
                & .ant-form-item-control-input-content {
                  max-height: 38px;
  
                  & .ant-input-number {
                    height: 38px;
                    border: 0;
  
                    & .ant-input-number-input {
                      border: 1px solid $line;
                    }
                  }
                }
              }

              & > .ant-row:first-child {
                display: flex;
                flex-direction: column;

                .ant-form-item-label {
                  text-align: left;
                }
              }

              & > .ant-row:nth-child(2) {
                & input {
                  &::placeholder {
                    font-size: 13px;
                  }
                }
              }

              & .ant-select {
                margin-right: 8px;

                & .ant-select-selector {
                  height: 38px;
                }
              }
            }

            label {
              margin-bottom: 0;

              &::after {
                content: none;
              }
            }

            .sub-kpi-item-wrapper {
              display: flex;
              flex-direction: column;

              .ant-form-item-label {
                text-align: left;
              }

              & .ant-row {
                & .ant-form-item-control-input-content {
                  max-height: 38px;
  
                  & .ant-input-number {
                    height: 38px;
                    border: 0;
  
                    & .ant-input-number-input {
                      border: 1px solid $line;
                    }
                  }
                }
              }

              & label {
                margin-top: 8px;
                font-weight: 400;
                font-size: 13px;

                &::after {
                  content: none;
                }
              }
            }

            .btn-add-subkpi {
              width: 138px;
              height: 29px;
              border: 1px solid $main-color;
              color: $main-color;
              background: #fff;
              margin-top: 10px;
            }

            .sub-kpi-item {
              display: flex;

              .w-496 {
                width: 496px;
              }
              .sub-kpi-target-value {
                margin-left: 8px;
                margin-right: 0;
                width: 68px;
                color: $text-main;
                border-color: $line;

                &.ant-input-number-status-error {
                  border-color: $alert !important;

                  input {
                    border-color: $alert !important;
                  }

                  &:focus, &:hover {
                    border-color: $main-color !important;
                    box-shadow: 0 0 0 2px rgb(60 179 113 / 20%);
                  }
                }

                input {
                  width: 68px !important;

                  &::placeholder {
                    font-size: 12px;
                  }
                }
              }
              button {
                width: 75px;
                height: 38px;
                border: 1px solid #f86767;
                color: #f86767;
                background: #fff;
                margin-left: 8px;
              }
            }

            .w-83 {
              width: 83px;
            }
          }
          & .calculation {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            margin-top: 7px;

            & .group-calculation {
              display: flex;
              align-items: flex-end;

              & .group-button {
                display: flex;
              }
            }
            label {
              font-size: 13px;
              font-weight: 400;
              color: $text-disable;
              margin-bottom: 12px;
              flex: 0 0 100%;
            }
            .ant-select-selector {
              background-color: $line;
              border: 1px solid $line;
              color: #fff;
              width: 80px;
              height: 28px;
            }
            .kpi-name-select .ant-form-item-control-input {
              min-height: 28px;

              & .ant-select-selection-item {
                line-height: 25px;
              }

              & .ant-select-arrow {
                color: #fff;
              }
            }
            .btn-grey {
              background: $line;
              border-radius: 3px;
              border: none;
              color: #fff;
              line-height: 28px;
              height: 28px;
              width: 78px;
              margin-right: 9px;
              display: flex;
              justify-content: center;
              align-items: center;
              @include br739 {
                width: 69px;
              }
              &.btn-erase {
                width: auto;
                font-size: 13px;
                padding: 0 23px;

                &:disabled {
                  cursor: no-drop;
                }
              }
              cursor: pointer;
            }
            .btn-white {
              border: 1px solid $line;
              box-sizing: border-box;
              border-radius: 3px;
              line-height: 27px;
              font-size: 13px;
              padding: 0 11px;
              margin-right: 9px;
              color: $line;
            }
            input {
              margin-right: 9px;
            }
            .btn-border-alert {
              margin-left: 20px;
              @include br1350 {
                margin-left: 0;
              }
            }
            .kpi-value-formula {
              & .ant-form-item-control-input {
                min-height: 28px;
              }

              & input {
                border-radius: 3px;
                font-size: 13px;
                margin-right: 9px;
                color: #333;
                width: 78px;
                height: 28px;
                padding: 4px 11px;
                &::placeholder {
                  color: $line;
                  font-size: 12px;
                }
                &:focus {
                  border-color: #808080;
                }
              }
            }
          }
        }
      }
      .btn-register {
        width: 115px;
        margin-top: 32px;
        font-weight: 400;
        display: block;
        height: 34px;
        padding-top: 6px;
        @include br739 {
          width: 100%;
        }
      }
      .btn-border-main {
        background-color: #fff;
        @include br739 {
          width: 100%;
        }
      }
    }

    .project-task {
      margin-top: 35px;
      input[type='checkbox'] + .ant-checkbox-inner {
        width: 12px;
        height: 12px;
        border: 1px solid #3cb371;
        position: relative;
        margin-left: -11px;
        border-radius: 2px;
        display: inline-block;
        margin-bottom: 1.5px;
      }
      input[type='checkbox']:checked + .ant-checkbox-inner:after {
        content: '\2714';
        position: absolute;
        top: -1px;
        left: 0;
        font-size: 11px;
        color: #fff;
        transform: scale(1);
        border: none;
      }
      h5 {
        margin-bottom: 10px;
      }
      .dontshow {
        display: flex;
        align-items: center;
        label {
          margin-bottom: 0;
          margin-left: 10px;
          font-weight: 400;
          color: $text-main;
        }
        .ant-checkbox:hover::after,
        .ant-checkbox-wrapper:hover .ant-checkbox::after {
          visibility: hidden;
        }
      }
      .box {
        margin-top: 8px;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 17px 15px 0;
        display: block !important;
        .form-group {
          + .form-group {
            margin-top: 18px;
          }
          label {
            margin-bottom: 10px;
          }
          input {
            padding: 0 10px;
            &.w-496 {
              width: 496px;
            }
            &::placeholder {
              color: $line;
            }
          }
        }
      }
      .btn-border-main {
        background-color: #fff;
        @include br739 {
          width: 100%;
        }
      }
      .btn-add-dashboard-template {
        width: 116px;
        height: 29px;
        color: $main-color;
        border: 1px solid $main-color;
        background: #fff;
        font-size: 13px;
      }
      .box-template-setting {
        & .ant-form-item {
          margin-bottom: 20px;

          &-label {
            & > label {
              color: $text-main;

              &::before {
                content: none;
              }
            }
          }
        }
        .ant-input {
          color: $text-main;
        }
        button {
          border-radius: 3px;
        }
        .btn-delete-dashboard-template {
          width: 75px;
          height: 38px;
          border: 1px solid $alert;
          color: $alert;
          background: #fff;
          margin-left: 12px;
        }
        .btn-add-image {
          width: 92px;
          height: 32px;
          border: 1px solid $line;
          background: $line;
          color: #fff;
          display: flex;
          align-items: center;

          span {
            margin-left: 11px;
          }
        }
        .btn-remove-image {
          width: 75px;
          height: 38px;
          border: 1px solid $alert;
          color: $alert;
          background: #fff;
          margin-left: 12px;
        }
        .dashboard-template-title {
          margin-bottom: 0;

          & .ant-col:last-child {
            & .ant-form-item-control-input-content {
              display: flex;
              flex-flow: row wrap;
            }
          }
        }
        .dashboard-template-sample-image {
          & .ant-form-item {
            margin-bottom: 0;
          }
          & .ant-form-item-control-input {
            min-height: 0;
          }

          & .ant-form-item-control-input-content {
            display: flex;
            align-items: flex-end;
          }

          & .ant-avatar {
            width: 543px;
            height: 363px;

            & img {
              width: 543px;
              height: 363px;
            }
          }
        }
        textarea {
          height: 38px;
        }
      }
    }

    .notification-settings {
      h5 {
        margin-bottom: 16px;
      }
      label {
        margin-bottom: 0;
      }
      form {
        li {
          & > label {
            font-weight: 400;
          }
        }
      }
      margin-top: 35px;
      .checkbox {
        + .checkbox {
          margin-top: 19px;
        }
        select {
          margin: 0 5px;
          width: 65px;
          padding: 0 10px;
        }
        label {
          color: $text-main;
        }
      }
    }

    .task-status {
      margin-top: 37px;
      .btn-add-status {
        width: 157px;
        height: 29px;
        border: 1px solid $main-color;
        color: $main-color;
        background-color: #fff;
      }
      .form-group {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        + .form-group {
          margin-top: 8px;
        }
        label {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 10px;
        }
        .w-496 {
          width: 496px;
          @include br739 {
            width: calc(100% - 170px);
          }
        }
        input {
          margin-right: 12px;
          padding: 0 11px;
          margin-bottom: 10px;
        }
        .dropdown {
          position: relative;
          margin-right: 12px;
          margin-bottom: 10px;
          a {
            padding: 0 11px;
            border: 1px solid #cccccc;
            box-sizing: border-box;
            border-radius: 3px;
            width: 70px;
            display: inline-flex;
            height: 38px;
            align-items: center;
          }
          .circle {
            width: 16px;
            height: 16px;
            display: inline-block;
            border-radius: 50%;
            border: 1px solid #cccccc;
            &-big {
              width: 22px;
              height: 22px;
            }
            &.black {
              background: $text-main;
            }
            &.line {
              background: $line;
            }
            &.alert {
              background: $alert;
            }
            &.blue {
              background: $blue;
            }
            &.orange {
              background: $orange;
            }
          }
          &.open {
            .dropdown-menu {
              opacity: 1;
              visibility: visible;
            }
          }
          .dropdown-menu {
            position: absolute;
            background-color: #fff;
            left: 0;
            top: 100%;
            width: 60px;
            border: 1px solid #cccccc;
            border-radius: 3px;
            opacity: 0;
            visibility: hidden;
            z-index: 1;
            transition: 0.2s all;
            ul {
              padding: 6px 2px;
              li {
                position: relative;
                text-align: right;
                padding: 0.5px 9px;
                &.active,
                &:hover {
                  background-color: $background-active;
                }
                svg {
                  position: absolute;
                  left: 10px;
                  top: 5px;
                }
              }
            }
          }
        }
        .btn-border-alert {
          margin-bottom: 10px;
        }
      }
      .btn-border-main {
        background-color: #fff;
        @include br739 {
          width: 100%;
        }
      }
    }

    .remove-cockpit {
      margin: 35px 0 22px;
      .notice {
        margin-bottom: 18px;
        display: flex;
        align-items: flex-end;

        p {
          padding-top: 0;
          margin-bottom: 0;
        }
      }
      .group-btn {
        .btn-border-alert {
          border: 1px solid $alert;
          background-color: #fff;
          border-radius: 3px;
          line-height: 26px;
          font-size: 13px;
          width: auto;
          color: $alert;
          height: 29px;
          font-weight: 400 !important;
          margin-bottom: 10px;
          text-align: center;
          // span {
          //   width: 130px;
          //   overflow: hidden;
          //   white-space: nowrap;
          //   text-overflow: ellipsis;
          // }
        }
      }
    }

    .edit-company {
      margin-top: 26px;
      .form-group {
        label {
          margin-bottom: 10px;
        }
        input {
          padding: 0 10px;
          &::placeholder {
            color: $line;
          }
        }
        + .form-group {
          margin-top: 18px;
        }
      }
      .btn-save {
        display: block;
        width: 81px;
        margin: 31px auto 0;
        padding: 5px 20px 5px;
        height: 34px;
        @include br739 {
          width: 100%;
        }
      }
    }

    .change-password {
      margin-top: 36px;
      &.padding-bottom {
        padding-bottom: 30px;
      }
      h5 {
        margin-bottom: 18px;
      }
      .btn-border-main {
        background-color: #fff;
        font-weight: 400 !important;
        @include br739 {
          width: 100%;
        }
      }
    }

    .select-color {
      display: inline-flex;
      flex-direction: row-reverse;
    }

    .plan-settings {
      margin-top: 35px;
      ul {
        li {
          display: flex;
          margin-bottom: 17px;
          @include br739 {
            flex-direction: column;
          }
          .name {
            min-width: 147px;
            font-size: 14px;
            font-weight: 700;
            margin-right: 15px;
          }
          .value {
            font-size: 14px;
            font-weight: 700;
            line-height: 1.55;
            color: #333;
          }
        }
      }
    }

    .permission {
      margin-top: 35px;
      & .member-item {
        display: flex;
        align-items: center;
        & .ant-col {
          width: 170px !important;
        }
        & .ant-form-item-no-colon {
          height: 100%;
        }
      }
      .btn-add-member {
        background-color: #fff !important;
        color: $main-color !important;
        border: 1px solid $main-color;
        margin-bottom: 20px;
      }
      .form-group {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        + .form-group {
          margin-top: 6px;
        }
        label {
          margin-bottom: 0;
          display: flex;
          align-items: center;
          margin-right: 45px;
          padding-top: 3px;
          margin-bottom: 10px;
          @include br739 {
            margin-right: 20px;
          }
          svg {
            margin-right: 8px;
          }
        }
        select {
          width: 210px;
          margin-right: 17px;
          margin-bottom: 10px;
          @include br739 {
            width: calc(100% - 212px);
          }
        }
        .btn-border-alert {
          margin-bottom: 10px;
        }
      }
      .btn-border-main {
        background-color: #fff;
        @include br739 {
          width: 100%;
        }
      }
      .ant-select {
        margin-right: 12px;
        &:not(:last-child) {
          color: $text-main;
        }
      }
      & .ant-form {
        margin-bottom: 8px;
        & .ant-col {
          width: 160px;
        }
      }
      & .member-name {
        max-width: 120px;
        display: inline-block;
        font-size: 14px;
        word-break: break-word;
        white-space: normal;
      }
    }
    .icon-scroll-top {
      position: fixed;
      right: 1%;
      bottom: 3%;
      cursor: pointer;
    }
  }
}
.btn-border-main {
  border: #3cb371 solid 1px !important;
  font-size: 13px;
  background: transparent;
  color: $main-color !important;
  line-height: 29px;
  height: 29px;
  padding: 0 23px;
  font-weight: 400;
  border-radius: 3px;
}
// .ant-select-dropdown {
//   width: 77px !important;
//   min-width: 77px !important;
// }
.kpi-remove-modal {
  width: 560px !important;
  height: 160px;
  &.ant-modal {
    position: static;
    padding-bottom: 0;
  }
  .ant-modal-content {
    border-radius: 3px;
    height: 100%;
    box-shadow: none;

    .ant-modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
    }

    p {
      font-weight: 700;
      font-size: 16px;
      color: $text-main;
      margin-bottom: 24px;
      text-align: center;
      line-height: 24px;
    }

    .btn {
      border: none;
      border-radius: 3px;
      cursor: pointer;
      font-size: 14px;
      color: #fff;
      line-height: 30px;
      height: 30px;
      font-weight: 400 !important;

      &-return {
        background-color: $line;
        width: 120px;
        margin-right: 16px;
      }

      &-remove {
        background-color: $alert;
        color: #fff;
        width: 77px;
      }
    }
  }

  .box-template-setting {
    display: block !important;
  }
}

.dropdown-color {
  & .ant-select-item-option {
    flex-direction: row-reverse;
    align-items: center;
  }
  & .ant-select-item-option-content {
    text-align: right;
  }
  & .ant-select-item-option-selected {
    & span {
      display: inline-block;
      margin-top: -5px;
    }
  }
}