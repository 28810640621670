* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
iframe {
  z-index: -1 !important;
}
input::placeholder {
  color: #cccc !important;
}
textarea::placeholder {
  color: #cccc !important;
}
body {
  background-color: $background;
  font-family: 'Noto Sans', 'Noto Sans JP' !important;
  // overflow: scroll !important;
  line-height: 1.3;
  font-weight: 400;
  color: #333;
  letter-spacing: 0.05em;
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: inherit;
}
.container {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.container-fluid {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}
.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.modal {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: backgroundModal 0.25s forwards;
  .modal-body {
    width: 560px;
    animation: bodyModal 0.25s forwards;
    max-width: calc(100% - 70px);
    top: 50%;
    // top: 100px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    z-index: 110;
    transform: translate(-50%, -50%);
    // transform: translateX(-50%);

    border-radius: 3px;
    // z-index: 10;
    .modal-inner {
      padding: 43px 40px 48px;
      max-height: 80vh;
      overflow-y: auto;
    }
    .close-modal {
      cursor: pointer;
      width: 28px;
      height: 28px;
      display: flex;
      background: #fff;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -14px;
      right: -15px;
      border: 1px solid $main-color;
      transition: 0.2s all;
      &:hover {
        background-color: $main-color;
        path {
          fill: #fff;
        }
      }
    }
  }
}
.dropdown {
  position: relative;
  &.open {
    .dropdown-menu {
      opacity: 1;
      visibility: visible;
    }
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  label {
    margin-bottom: 0;
    margin-left: 10px;
    font-weight: 400;
  }
}
textarea {
  border: 1px solid #cccccc;
  border-radius: 3px;
  letter-spacing: 0.05em;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: inherit;
  }
}
input {
  font-size: 14px;
  color: $text-main;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
  height: 38px;
  padding: 0 10px;
  font-family: 'Noto Sans', 'Noto Sans JP';
  max-width: 100%;
  letter-spacing: 0.05em;
  &[type='checkbox']:not(.ant-checkbox-input) {
    opacity: 0;
    width: 12px;
    height: 12px;
    position: relative;
    z-index: 2;
    &:checked + .checkmark {
      background-color: $main-color;
      &::after {
        content: '\2714';
        position: absolute;
        top: -3px;
        left: 0;
        font-size: 11px;
        color: #fff;
      }
    }
    + .checkmark {
      width: 12px;
      height: 12px;
      border: 1px solid $main-color;
      position: relative;
      margin-left: -11px;
      border-radius: 2px;
      display: inline-block;
    }
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &::placeholder {
    color: inherit;
  }
}
select {
  font-family: 'Noto Sans', 'Noto Sans JP';
  letter-spacing: 0.05em;
  font-size: 14px;
  color: $text-main;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
  height: 38px;
  padding: 0 35px 0 10px;
  appearance: none;
  max-width: 100%;
  // background: #fff url("../images/down-3.png") no-repeat calc(100% - 11px) 10px;
  &:focus {
    outline: none;
  }
}
label:not(.ant-checkbox-wrapper) {
  font-weight: 700;
  font-size: 14px;
  display: block;
  margin-bottom: 8px;
  letter-spacing: 0.05em;
}
ul {
  list-style: none;
  li {
    list-style: none;
  }
}
.text-accent {
  color: $text-accent;
}
.text-alert {
  color: $alert;
}
.text-blue {
  color: $blue;
}
.text-main {
  color: $text-main;
}
.btn {
  display: inline-block;
  text-align: center;
  border: 1px solid $main-color;
  background-color: $main-color;
  color: #fff;
  font-size: 16px;
  font-weight: 700 !important;
  padding: 9px 20px 5px;
  border-radius: 5px;
  letter-spacing: 0.05em;
  height: 42px;
  line-height: normal;
  font-family: 'Noto Sans', 'Noto Sans JP' !important;
  &.btn-small {
    font-size: 14px;
    font-weight: 400;
    height: 30px;
    line-height: 28px;
    padding: 0 23px;
    border-radius: 3px;
    color: white !important;
  }
  &.btn-round {
    border-radius: 100px;
  }
  &.btn-transparent {
    background-color: transparent;
    color: $main-color;
    box-shadow: none;
    border: 1px solid;
  }
  &.btn-block {
    width: 100%;
  }
  &:disabled {
    background-color: #999;
    border-color: #999;
    color: #666;
  }
}
.form-group {
  position: relative;
}
.form-control {
  position: relative;
  font-family: 'Noto Sans JP';
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.d-i-flex {
  display: inline-flex;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.w-100 {
  width: 100%;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.custom-checkbox {
  position: relative;
  display: inline-flex;
  input {
    opacity: 0;
    position: relative;
    z-index: 2;
    &:checked ~ .checkmark::after {
      display: flex;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    border: 1px solid #000;
    &::after {
      content: '✓';
      display: none;
      width: 14px;
      height: 14px;
      align-items: center;
      justify-content: center;
    }
  }
}

@keyframes backgroundModal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bodyModal {
  from {
    margin-top: -100px;
  }
  to {
    margin-top: 0;
  }
}
