#cockpit-creation{
    .main-content{
        padding: 17px 54px;
    }
    .block-kpi{
        margin-top: 158px;
        .form-group{
            margin-bottom: 40px;
            input{
                margin-right: 32px;
            }
            .delete{
                font-size: 12px;
                font-weight: 700;
                color: #000;
                margin-left: 26px;
            }
        }
    }
    .form-group{
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        label{
            margin-bottom: 0;
            min-width: 110px;
            margin-right: 21px;
            font-size: 12px;
            font-weight: 700;
            color: #000;
            letter-spacing: 0;
        }
        input{
            background: #FFFFFF;
            border: 1px solid #333333;
            border-radius: 7px;
            line-height: 39px;
            font-size: 16px;
            color: #BDBDBD;
            padding: 0 16px;
            &.width-1{
                width: 503px;
            }
            &.width-2{
                width: 253px;
            }
            &.width-3{
                width: 144px;
            }
            &.width-4{
                width: 206px;
            }
            &::placeholder{
                color: #BDBDBD;
            }
        }
    }
    .add-kpi{
        display: block;
        font-size: 12px;
        font-weight: 700;
        color: #616161;
        margin-top: 49px;
        margin-bottom: 21px;
    }
    .btn-submit{
        width: 236px;
    }
}

#invite-users{
    .main-content{
        padding: 230px 54px;
    }
    input{
        background: #FFFFFF;
        border: 1px solid #333333;
        border-radius: 7px;
        line-height: 39px;
        font-size: 16px;
        color: #BDBDBD;
        padding: 0 16px;
        width: 503px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 170px;
        &::placeholder{
            color: #BDBDBD;
        }
    }
    
    .btn-submit{
        width: 236px;
    }
}