.open-sidebar {
  .nav-sidebar {
    left: 0;
    .js-open-sidebar {
      // opacity: 0;
      // visibility: hidden;
    }
  }
}
.nav-sidebar {
  position: fixed;
  top: 54px;
  left: -152px;
  width: 200px;
  height: calc(100% - 50px);
  background-color: $text-accent;
  transition: 0.2s all;
  z-index: 10;
  @include br1079 {
    top: 54px;
    left: -200px;
    height: calc(100% - 47px);
  }
  // .sidebar-button {
  //     @include br1079 {
  //         display: none !important;
  //     }
  // }
  .js-open-sidebar {
    // opacity: 1;
    // visibility: visible;
    // position: absolute;
    // left: 100%;
    // background-color: #2E8B57;
    // width: 35px;
    // height: 35px;
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    padding: 13px 12px;
    transition: 0.2s all;
    // @include br1079 {
    //     display: none;
    -webkit-transition: 0.2s all;
    z-index: 10;
    @include br1079 {
      top: 54px;
      left: -200px;
      height: calc(100% - 47px);
    }
    // .sidebar-button {
    //     @include br1079 {
    //         display: none !important;
    //     }
    // }
  }
  .close-sidebar {
    display: flex;
    padding: 13px 12px;
    justify-content: flex-end;
    cursor: pointer;
  }
  ul {
    li {
      line-height: 48px;
      &.active,
      &:hover {
        a,
        button {
          background-color: #fff;
          color: $text-accent;
          path {
            fill: $text-accent;
          }
        }
      }
      a,
      button {
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        padding: 0 17px;
        path {
          fill: #fff;
        }
        .icon {
          min-width: 30px;
          display: inline-flex;
          margin-right: 2px;
        }
        background-color: transparent;
        border: none;
        width: 100%;
        cursor: pointer;
      }
    }
  }
  &-modal {
    width: 560px !important;
    height: 168px;
    &.ant-modal {
      position: static;
      padding-bottom: 0;
    }

    .ant-modal-content {
      border-radius: 3px;
      height: 100%;

      .ant-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
      }

      p {
        font-weight: 700;
        font-size: 16px;
        color: $text-main;
        margin-bottom: 24px;
      }

      .btn {
        border: none;
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
        color: #fff;
        line-height: 30px;
        height: 30px;
        font-weight: 400 !important;

        &-return {
          background-color: $line;
          width: 77px;
          margin-right: 16px;
        }

        &-logout {
          background-color: $main-color;
          width: 121px;
        }
      }
    }
  }
}
