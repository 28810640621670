#task{
    .main-content{
        margin-top: 51px;
        padding: 0;
    }
    table{
        border-collapse: collapse;
        text-align: left;
        tr{
            border-bottom: 1px solid #000;
            th{
                border: 1px solid #000000;
                line-height: 49px;
                padding: 0 10px;
                font-size: 16px;
                font-weight: 900;
                color: #000;
                background-color: #fff;
            }
            td{
                font-size: 16px;
                font-weight: 900;
                color: #000;
                line-height: 49px;
                padding: 0 10px;
                position: relative;
                svg{
                    position: absolute;
                    right: 11px;
                    top: 19px;
                    display: inline-block;
                    z-index: 1;
                }
            }
        }
    }
}