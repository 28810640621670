#dashboard {
  font-family: 'Noto Sans', 'Noto Sans JP' !important;
  .main-content {
    padding-left: 37px;
    .kpi-edit {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 23px;
      ~ .kpi-edit {
        @include br739 {
          display: none;
        }
      }
      .title {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 27px;
        font-weight: 700;
        margin-bottom: 0;
        &.kpi-edit-title {
          flex-flow: row wrap;
          align-items: center;
        }
        h3 {
          font-size: 20px;
          color: $text-main;
          font-weight: 700;
          margin-bottom: 26px;
        }
        .group-btn {
          display: flex;
          @include br739 {
            display: none;
          }
          .btn-white {
            margin-right: 24px;
            border-color: $text-disable;
            color: $text-disable;
            background-color: #fff;
          }
        }
        & > .filter-date {
          & span {
            font-weight: 400;
            color: $text-main;
          }
        }
      }
      .btn-border-main {
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        padding-right: 19px;
        background-color: #fff;
        @include br739 {
          display: none;
        }
        svg {
          margin-left: 11px;
        }
      }
      .setting {
        display: none;
        position: relative;
        ul {
          position: absolute;
          border: 1px solid $line;
          border-radius: 3px;
          background: #ffffff;
          z-index: 1;
          width: 141px;
          top: 100%;
          right: 0;
          left: auto;
          padding: 5px 3px;
          li {
            line-height: 26px;
            padding: 0 7px;
            &.active {
              background-color: $background-active;
            }
            a {
              font-size: 14px;
              color: $text-main;
            }
          }
        }
        @include br739 {
          display: inline-flex;
        }
      }
    }

    .filter-box {
      .filter-by-member {
        display: flex;
        align-items: center;

        .ant-select {
          height: 38px;
        }
      }
      .inner {
        display: block;
      }
      .ul-list-member {
        border: 1px solid #cccccc;
        border-radius: 3px;
        padding: 5px 8px 0px 13px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        & > label > span:last-child {
          color: $text-main;
        }
        .ant-checkbox-wrapper {
          margin-right: 8px;
        }
        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
          margin-left: 0;
        }
      }
      background-color: #fff;
      padding: 27px 15px;
      &.box-transparent {
        background: transparent;
        padding: 0;
        @include br739 {
          background: rgba(0, 0, 0, 0.3);
        }
        .groups {
          align-items: flex-end;
          .form-group {
            width: 100%;
          }
          @include br739 {
            align-items: flex-start;
          }
        }
      }
      @include br739 {
        background: rgba(0, 0, 0, 0.3);
        position: fixed;
        z-index: 100;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .body {
        @include br739 {
          width: 560px;
          max-width: calc(100% - 70px);
          top: 50%;
          background-color: #fff;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 3px;
          z-index: 10;
        }
        .inner {
          @include br739 {
            padding: 24px;
            max-height: 80vh;
            overflow-y: auto;
          }
          h3.title {
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            color: $main-color;
            margin-bottom: 16px;
            display: none;
            @include br739 {
              display: block;
            }
          }
        }
      }
      .close-modal {
        display: none;
        width: 28px;
        height: 28px;
        display: flex;
        background: #fff;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -14px;
        right: -15px;
        border: 1px solid $main-color;
        transition: 0.2s all;
        &:hover {
          background-color: $main-color;
          path {
            fill: #fff;
          }
        }
        @include br739 {
          display: flex;
        }
      }
      .groups {
        display: flex;
        flex-wrap: wrap;
        @include br739 {
          flex-direction: column;
        }
        .form-group {
          margin-right: 10px;
          margin-bottom: 10px;
          &.member {
            select {
              width: 254px;
              @include br739 {
                width: 100%;
              }
            }
          }
          select {
            @include br739 {
              width: calc(50% - 30px);
            }
          }
          label {
            margin-bottom: 10px;
          }
          span {
            font-size: 14px;
            color: $text-main;
            margin: 0 4px;
          }
        }
        .checkboxs {
          margin-bottom: 10px;
          width: calc(100% - 216px);
          @include br739 {
            width: 100%;
          }
          .group-btn {
            margin-left: auto;
            margin-bottom: 15px;
            @include br739 {
              display: none;
            }
            .action {
              background-color: #fff;
              border-color: $text-disable;
              color: $text-disable;
            }
            .action:hover {
              color: $main-color;
            }
            .add {
              color: #8e8e8e;
            }
            .add:hover {
              color: $main-color;
            }
          }
          .btn {
            font-size: 16px;
            height: 34px;
            line-height: 32px;
            @include br739 {
              width: calc(60% - 5px);
            }
          }
          .btn-grey {
            margin-left: 16px;
            border-color: $line;
            background-color: $line;
            @include br739 {
              width: calc(40% - 5px);
              margin-left: 0;
            }
          }
        }
      }
      .group-btn {
        display: flex;
        margin-top: 14px;
        @include br739 {
          justify-content: space-between;
        }
        .btn-white {
          margin-left: 24px;
          border-color: $text-disable;
          color: $text-disable;
          background-color: #fff;
        }
      }
    }

    #userKpiUpdateForm {
      .ant-form-item-explain-connected {
        position: absolute;
        bottom: -50%;
      }
      .ant-form-item-has-error {
        .ant-input-number {
          border-color: $alert;
          box-shadow: none;
        }
      }
    }

    .tables-kpi-edit {
      border: 1px solid #cccccc;
      border-radius: 8px;

      .ant-table-container {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        & table > thead > tr:first-child th {
          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }

      .ant-table {
        border-radius: 8px;

        table {
          border-radius: 8px 8px 0 0;
        }
      }
      .ant-table-thead {
        th {
          font-weight: 700;
          color: $main-color;

          &:first-child {
            padding-left: 66px;
          }

          &::before {
            content: none;
          }
        }
      }
      .ant-form-item-explain-connected {
        position: absolute;
        bottom: -50%;
      }
      .ant-form-item-has-error {
        .ant-input-number {
          border-color: $alert;
          box-shadow: none;
        }
      }
      .ant-table-content {
        &::-webkit-scrollbar {
          height: 10px;
        }
        &::-webkit-scrollbar-track {
          display: none;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $line;
          border-radius: 8px;
        }
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        width: 240px;
      }
      .ant-table-tbody > tr:nth-child(2) {
        & > td {
          & > .target-value-conversion {
            position: relative;
            left: -120px;
            z-index: 1;
          }
          & > .target-value-conversion-text {
            text-align: left;
            margin-left: -16px;
            cursor: pointer;
          }
        }
      }
      .ant-input-number-input {
        color: $text-main;
      }
      .ant-table-body {
        min-height: 630px !important;

        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-track {
          display: none;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $line;
          border-radius: 8px;
        }
      }
      .ant-table-tbody > tr.ant-table-row:hover > td,
      .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background: $background-active;
      }
      .ant-input-number-addonafter {
        & > div {
          &:last-child {
            width: 40px !important;
          }
        }
      }
      .ant-input-number-input {
        padding-right: 55px !important;
      }
      & .kpi-edit-target-value {
        width: 100%;
        & input {
          padding-right: 15px !important;
        }
      }
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 27px;
      .page-title {
        font-size: 20px;
        font-weight: 700;
        color: $text-main;
      }
      .btn-report {
        display: flex;
        align-items: center;
        @include br739 {
          display: none;
        }
        svg {
          margin-right: 7px;
        }
      }
      .setting {
        display: none;
        position: relative;
        ul {
          position: absolute;
          border: 1px solid $line;
          border-radius: 3px;
          background: #ffffff;
          z-index: 1;
          width: 141px;
          top: 100%;
          right: 0;
          left: auto;
          padding: 5px 3px;
          li {
            line-height: 26px;
            padding: 0 7px;
            &.active {
              background-color: $background-active;
            }
            a {
              font-size: 14px;
              color: $text-main;
            }
          }
        }
        @include br739 {
          display: inline-flex;
        }
      }
    }

    .ant-table-cell {
      input {
        text-align: right;
      }
      .ant-input-number-addonafter {
        .ant-input-number-input-wrap + div {
          width: 30px;
          height: 34px;
          position: absolute;
          top: 0;
          right: 0;
          border: none;
          border-left: 1px solid #d9d9d9;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
        input {
          text-align: right;
          padding-right: 40px;
        }
      }
    }

    .filter {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin-bottom: 9px;
      @include br739 {
        flex-direction: column;
        align-items: baseline;
      }
      .form-group {
        .display-setting {
          span {
            color: $main-color;
            letter-spacing: 0.05em;
          }
        }
        margin-right: 16px;
        margin-bottom: 15px;
        @include br739 {
          margin-right: 0;
          width: 100%;
        }
        label {
          margin-bottom: 10px;
        }
        .dropdown {
          @include br739 {
            width: calc(50% - 11px);
            justify-content: space-between;
          }
        }
        .select-custom {
          background: #ffffff;
          border: 1px solid $line;
          border-radius: 3px;
          font-size: 14px;
          padding: 0 10px;
          display: inline-flex;
          line-height: 36px;
          align-items: center;
          @include br739 {
            width: 100%;
          }
          + .dropdown-menu {
            top: calc(100% + 8px);
            border: 1px solid #cccccc;
            border-radius: 3px;
            width: 280px;
            background: #fff;
            z-index: 10;
            .title {
              display: flex;
              justify-content: center;
              margin-top: 9px;
              margin-bottom: 2px;
              .nav {
                display: flex;
                align-items: center;
                a {
                  display: flex;
                }
                span {
                  font-size: 14px;
                  color: $text-main;
                  margin: 0 9px;
                }
              }
            }
            .table-calendar {
              text-align: center;
              overflow: hidden;
              table {
                width: calc(100% + 2px);
                margin: 0 -1px -1px;
                border-collapse: collapse;
                tr {
                  th {
                    font-size: 12px;
                    color: $text-disable;
                    font-weight: 500;
                    padding: 11.7px 0;
                  }
                  td {
                    font-size: 12px;
                    color: $text-main;
                    border: 1px solid $line;
                    padding: 11.7px 0;
                    cursor: pointer;
                    transition: 0.2s all;
                    &.selected,
                    &:hover {
                      background-color: $main-color;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
          svg {
            margin-left: 10px;
          }
        }
        span {
          font-size: 14px;
          margin-right: -2px;
          color: $text-disable;
        }
        .ant-select-selection-item {
          color: $text-main;
        }
        select {
          margin-right: 4px;
          @include br739 {
            width: 100%;
            margin-bottom: 10px;
          }
        }
        .btn-display {
          margin-left: 7px;
          position: relative;
          top: 5px;
          padding: 0 26px;
          @include br739 {
            display: none;
          }
        }
      }
      .group-btn {
        margin-left: auto;
        margin-bottom: 15px;
        @include br739 {
          display: none;
        }
        a {
          background-color: #fff;
          border-color: $text-disable;
          color: $text-disable;
        }
      }
    }

    .kpi-edit-dashboard {
      display: flex;
      flex-flow: column-reverse wrap;

      & > div {
        width: 100%;

        & .kpi-edit-user-name {
          font-weight: 700;
          font-size: 14px;
          word-break: break-word;
          line-height: 1.8;
          display: inline-block;
          width: 155px;
        }

        &:first-child {
          & > div {
            margin-top: 24px;

            & .form-group {
              &:first-child {
                order: 1;
                margin-left: 16px;
              }
            }
          }
        }

        &:nth-child(2) {
          & .form-group {
            margin-bottom: 16px;

            & > .ant-select {
              width: 120px;
              height: 38px;
              color: $text-main;
              font-size: 14px;
            }
          }
        }

        & .ant-table-body {
          min-height: 215px !important;
        }
      }
    }

    .table-dashboard {
      border-radius: 8px;

      &.table-dashboard-selects {
        & .inner-total > .percent .percent,
        & .inner-total > .percent .percent-total, 
        & .inner-total > .percent.percent-wrapper .percent-item {
          position: static;
          width: 100%;
        }

        & .inner.inner-total {
          flex-direction: column;
        }

        & .inner-total > .percent .percent-total {
          width: 100%;
        }

        & .ant-table-thead > tr > th {
          text-align: left !important;
        }

        & .inner-total > .percent.percent-wrapper {
          flex-direction: row;
          justify-content: flex-start;
        }

        & .ant-table-thead > tr > th:nth-child(2), .ant-table-tbody > tr > td:nth-child(2) {
          width: 360px;
        }
      }

      &.table-dashboard-2-kpi {
        // & .inner-total > .percent .mb-5:nth-child(1) {
        //   left: -150px;
        // }
        & .ant-table-thead > tr > th:not(:first-child, :nth-child(2)) {
          text-align: left !important;
          & > span {
            // margin-right: 268px;
          }
        }

        .ant-table-tbody > tr > td:nth-child(2) {
          width: 360px;
        }

        & .inner-total > .percent.percent-wrapper .percent-item {
          left: -32%;
        }

        & .inner-total > .percent .percent-total {
          left: -32%;
        }
        & .custom-inner {
          position: relative;
          left: -150px;
        }
        & .custom-inner-first {
          left: unset !important;
        }
        & .subkpi {
          width: unset;
          .value {
            right: -56%;
          }
        }
      }

      &.table-dashboard-3-kpi {
        & .ant-table-thead > tr > th:not(:first-child, :nth-child(2)) {
          // & > span {
          //   margin-right: 90px;
          // }
        }

        & .inner-total > .percent.percent-wrapper .percent-item {
          left: -25%;
          min-width: 46%;
        }

        & tr.ant-table-row >.custom-position:nth-child(2) .percent-item {
          min-width: unset !important;
        }

        & .inner-total > .percent .percent-total {
          left: -25%;
          min-width: 46%;
        }
      }

      & .kpi-total-title {
        position: relative;

        & > span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .ant-table-container {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        & table > thead > tr:first-child th {
          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }

      .ant-pagination {
        & > .ant-pagination-prev {
          display: none;
        }
      }

      .ant-table {
        border-radius: 8px;
        border: 1px solid #cccccc;

        table {
          border-radius: 8px 8px 0 0;
        }
      }

      .ant-table-thead {
        th {
          &:first-child {
            padding-left: 66px;
            min-width: 200px !important;
          }
          font-weight: 700;
          color: $main-color;
          p {
            margin-bottom: 0;
            display: block;
            max-width: 500px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: auto;
          }
          &::before {
            content: none;
          }
        }
      }
      .ant-table-content {
        &::-webkit-scrollbar {
          height: 10px;
        }
        &::-webkit-scrollbar-track {
          display: none;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $line;
          border-radius: 8px;
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          vertical-align: top;
          position: relative;
        }
      }
      .ant-table-body {
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-track {
          display: none;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $line;
          border-radius: 8px;
        }
      }
      .ant-table-tbody > tr.ant-table-row:hover > td,
      .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background: $background-active;
      }
      .ant-table-thead > tr > th:nth-child(2),
      .ant-table-tbody > tr > td:nth-child(2) {
        width: 200px;
      }
      .ant-table-thead > tr > th:first-child,
      .ant-table-tbody > tr > td:first-child,
      .ant-table-thead > tr > th:nth-child(2),
      .ant-table-tbody > tr > td:nth-child(2) {
        text-align: left !important;
      }
      .ant-table-thead > tr > th {
        text-align: center !important;
      }
    }

    .table-dashboard-detail {
      .ant-pagination {
        & > .ant-pagination-prev {
          display: none;
        }
      }
      .ant-table-content {
        &::-webkit-scrollbar {
          height: 10px;
        }
        &::-webkit-scrollbar-track {
          display: none;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $line;
          border-radius: 8px;
        }
      }
      .ant-table-thead > tr > th {
        color: $main-color;
        font-weight: 700;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        min-width: 200px;
      }
      .ant-table-tbody {
        .ant-input-number-input {
          color: $text-main;
        }
        .ant-table-row:not(:first-child) {
          .ant-table-cell:first-child {
            color: $text-main;
            font-weight: 700;
          }
        }
      }
    }

    .inner {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    .inner-total {
      align-items: baseline;
    }

    .table {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 8px;
      overflow-x: auto;
      height: calc(100vh - 250px);
      overflow-y: auto;
      table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        background-color: #fff;
        border-radius: 8px;
        td,
        th {
          padding: 8px 4px;
          border-radius: 8px;
          &:nth-child(1) {
            padding-left: 42px;
          }
        }
        th {
          font-size: 14px;
          font-weight: 700;
          color: $text-accent;
          height: 52px;
          &.member {
            width: 185px;
            min-width: 185px;
          }
          &.sales {
            width: 170px;
            min-width: 170px;
          }
          &.kpi {
            width: 191px;
            min-width: 191px;
            text-align: right;
            padding: 8px 22px;
          }
        }
        td {
          font-size: 14px;
          color: $text-main;
          height: 56px;
          position: relative;
          padding-top: 18px;
          padding-bottom: 18px;
          vertical-align: baseline;
          &.member {
            .name {
              font-size: 13px;
              font-weight: 700;
              color: $text-main;
              display: flex;
              justify-content: center;
              align-items: center;
              vertical-align: middle;
              svg {
                margin-right: 8px;
              }
            }
          }
          &.sales {
            font-size: 13px;
            color: $text-main;
            b {
              font-size: 16px;
              font-weight: 700;
            }
          }
          &.kpi {
            .inner {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding-right: 17px;
              > div {
                display: inline-flex;
              }
            }
            .percent {
              flex-direction: column;
              font-size: 13px;
              span {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: -3px;
              }
              b {
                font-size: 20px;
                font-weight: 700;
              }
            }
            ul {
              margin-top: 5px;
              li {
                .name {
                  width: 40px;
                  min-width: 40px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 4px;
                }
                .value {
                  font-size: 12px;
                  color: $text-disable;
                  line-height: 21px;
                  display: inline-block;
                }
              }
            }
          }
        }
        tr {
          border-bottom: 1px solid #ccc;
          &.active,
          &:hover {
            background-color: $background-active;
          }
          &.active {
            td {
              padding-top: 14px;
              padding-bottom: 14px;
            }
          }
        }
      }
    }

    .table-kpi-edit {
      table {
        tr {
          &.rate {
            .kpi {
              text-align: left;
              padding-left: 32px;
            }
          }
          td,
          th {
            &:nth-child(1) {
              padding-left: 0;
            }
          }
          th {
            &.member {
              width: 196px;
              min-width: 196px;
            }
            &.sales {
              width: 82px;
              min-width: 82px;
            }
            &.kpi {
              width: 104px;
              min-width: 104px;
              text-align: right;
              padding: 8px 22px;
            }
          }
          td {
            height: 54px;
            padding-top: 14px;
            padding-bottom: 15px;
            vertical-align: middle;
            position: relative;
            &.member {
              .name {
                font-size: 14px;
              }
            }
            &.sales {
              font-size: 14px;
            }
            &.kpi {
              text-align: right;
              padding-right: 9px;
            }
            input {
              width: 64px;
              height: 30px;
              padding: 0 10px;
              border-color: transparent;
              text-align: right;
              background: transparent;
              &:focus {
                border-color: $line;
                background: #fff;
              }
            }
            .tooltip {
              top: calc(100% + 17px);
              left: 47px;
              padding: 25px 23px;
              position: absolute;
              background-color: #fff;
              width: 295px;
              border: 1px solid $main-color;
              border-radius: 10px;
              z-index: 10;
              &::before {
                content: url('../../../assets/images/tooltip-arrow_prev_ui.png');
                position: absolute;
                bottom: calc(100% - 3px);
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
              }
              &::after {
                content: '';
                width: 33px;
                height: 1px;
                background-color: #fff;
                position: absolute;
                bottom: calc(100% - 0px);
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                z-index: 11;
              }
              .title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;
                p {
                  font-size: 13px;
                  color: $text-main;
                  font-weight: 700;
                }
              }
              ul {
                li {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 12px;
                  .t-name {
                    font-size: 13px;
                    color: $text-main;
                  }
                  .t-value {
                    font-size: 13px;
                    font-weight: 700;
                    color: $text-accent;
                  }
                }
              }
              .add-sub-kpi {
                font-size: 14px;
                font-weight: 700;
                color: $main-color;
                display: flex;
                align-items: center;
                svg {
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }

    .table-progress-report {
      table {
        tr {
          &.empty {
            input {
              border-color: $line;
            }
          }
          td,
          th {
            &:nth-child(1) {
              padding-left: 0;
            }
          }
          th {
            &.date {
              width: 196px;
              min-width: 196px;
            }
            &.sales {
              width: 82px;
              min-width: 82px;
            }
            &.kpi {
              width: 104px;
              min-width: 104px;
              text-align: center;
              padding: 8px 22px;
            }
          }
          td {
            height: 54px;
            padding-top: 11px;
            padding-bottom: 12px;
            vertical-align: middle;
            position: relative;
            &.date {
              font-weight: 700;
            }
            &.sales {
              font-size: 14px;
            }
            &.kpi {
              text-align: right;
              padding-right: 9px;
            }
            input {
              width: 80px;
              height: 30px;
              padding: 0 10px;
              border-color: transparent;
              text-align: right;
              background: transparent;
              &:focus {
                border-color: $line;
                background: #fff;
              }
            }
            .tooltip {
              top: calc(100% + 9px);
              left: calc(50% + 28px);
              padding: 21px 23px;
              position: absolute;
              background-color: #fff;
              width: 248px;
              border: 1px solid #3cb371;
              border-radius: 10px;
              z-index: 10;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
              &::before {
                content: url('../../../assets/images/tooltip-arrow_prev_ui.png');
                position: absolute;
                bottom: calc(100% - 3px);
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
              }
              &::after {
                content: '';
                width: 33px;
                height: 1px;
                background-color: #fff;
                position: absolute;
                bottom: calc(100% - 0px);
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                z-index: 11;
              }
              .title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;
                p {
                  font-size: 13px;
                  color: $text-main;
                  font-weight: 700;
                }
              }
              ul {
                li {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 12px;
                  .t-name {
                    font-size: 13px;
                    color: $text-main;
                  }
                  .t-value {
                    font-size: 13px;
                    font-weight: 700;
                    color: $text-main;
                    &.alert {
                      color: $alert;
                    }
                  }
                }
              }
              .add-sub-kpi {
                font-size: 14px;
                font-weight: 700;
                color: $main-color;
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                svg {
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }

    .modal {
      &.add-project,
      &.add-sub-kpi {
        .modal-inner {
          padding-bottom: 40px;
        }
        h3 {
          text-align: center;
          font-size: 16px;
          font-weight: 700;
          color: $main-color;
          margin-bottom: 20px;
        }
        .form-group {
          + .form-group {
            margin-top: 17px;
          }
          label {
            color: $text-main;
            margin-bottom: 10px;
          }
          .w-219 {
            width: 219px;
            @include br739 {
              width: 100%;
            }
          }
          .w-206 {
            width: 206px;
            @include br739 {
              width: 100%;
            }
          }
          input {
            color: $line;
          }
        }
        .btn {
          margin: 16px auto 0;
          display: block;
          @include br739 {
            width: 100%;
          }
        }
      }
      &.display-setting {
        .modal-body {
          width: 568px;
          .modal-inner {
            padding: 43px 33px 48px;
          }
        }
        h3 {
          text-align: center;
          font-size: 16px;
          font-weight: 700;
          color: $main-color;
          margin-bottom: 20px;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            padding: 0 8px;
            margin-bottom: 20px;
            label {
              color: $text-main;
            }
          }
        }
        .group-btn {
          margin-top: 5px;
          text-align: center;
          .btn-clear {
            background-color: $line;
            border-color: $line;
            margin-left: 10px;
          }
        }
      }
      .modal-inner {
        padding-bottom: 40px;
        @include br739 {
          padding: 25px;
        }
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          background-color: $line;
        }
      }
      .alert {
        color: $alert;
        font-size: 14px;
        margin-top: 18px;
        margin-bottom: 20px;
      }
      .form-group.error {
        input {
          border-color: $alert;
        }
      }
    }

    .table-dashboard .ant-table-thead th,
    .table-dashboard .ant-table-tbody td {
      min-width: 340px !important;
      width: 360px;
    }
  }

  .edit-project {
    .modal-inner {
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $line;
        border-radius: 8px;
      }
    }
  }

  .modal.edit-project h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #3cb371;
    margin-bottom: 20px;
  }

  .modal.edit-project
    .modal-inner
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .modal.edit-project .btn {
    margin: 16px auto 0;
    display: block;
  }

  .ul-lst-kpi .btn {
    margin: 16px auto 0;
    display: inline-block !important;
    margin-right: 20px !important;
  }

  .group-btn-center {
    text-align: center;

    .btn {
      font-weight: 400 !important;
      font-size: 14px;
      border-radius: 3px;
      color: #fff;
      height: 30px;
    }

    .btn-clear {
      width: 92px;
      border: 1px solid $line;
      background: $line;
    }

    .btn-update {
      width: 77px;
      background: $main-color;
      border: 1px solid $line;
    }
  }

  .subkpi {
    padding-top: 0;
    margin-bottom: 0px;
    margin-top: 20px;
    //height: 120px;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    // overflow-y: auto;
    padding-left: 0;
    // width: 240px;
    &.custom-subkpi {
      .value {
        right: unset !important;
      }
    }
    li {
      justify-content: space-between;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      + li {
        margin-top: 8px;
      }
    }
    .custom-subkpi {
      flex-direction: column-reverse;
      align-items: unset;
      .value {
        margin-top: 8px;
        right: unset;
        text-align: left;
      }
    }
    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 120px;
      display: block;
      text-align: left;
      font-size: 12px;
      color: $text-disable;
    }

    .value {
      display: inline-block;
      // margin-left: 30px;
      min-width: 50px;
      text-align: center;
      font-size: 13px;
      position: relative;
      right: -32%;
    }
  }

  // .subkpi-turnover {
  //   margin-top: 0px;
  // }

  .value-turnover {
    font-weight: 600;
    // margin-top: 20px;
    line-height: 40px;
  }

  .value-turnover-total {
    font-weight: 600;
  }

  .checkbox-show-kpi {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.popup-sub-kpi {
  background: #ffffff !important;
  border-radius: 5px !important;
  border: 1px solid $main-color !important;
  z-index: 1 !important;
  padding: 20px !important;
  width: 350px !important;

  & tbody {
    & > tr{
      & .form-edit-targetvalue, & .form-edit-name {
        position: relative;
        margin-bottom: 15px;

        & .ant-form-item-explain {
          position: absolute;
          bottom: -15px;
        }
      }
      & .form-edit-name {
        & .ant-form-item-explain {
          left: 0;
        }
      }
      & .form-edit-targetvalue {
        & .ant-form-item-explain {
          right: 0;
        }
      }
      & > td:last-child {
        & .ant-form-item-has-error {
          // margin-bottom: 15px;

          & .ant-form-item-explain-error {
            text-align: right !important;
          }
        }
      }
    }
  }

  // .ant-dropdown-arrow {
  //     background-color: $main-color
  // }

  // &::before{
  //     content: url("../../../assets/images/tooltip-arrow_prev_ui.png");
  //     position: absolute;
  //     bottom: calc(100% - 3px);
  //     left: 50%;
  //     transform: translateX(-50%);
  //     z-index: 1;
  // }
}

#dashboard .main-content .inner-total > .percent {
  width: 100%;

  &.percent-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;

    & .percent-item {
      position: relative;
      left: -25%;
    }
  }
  
  & .percent {
    text-align: center;
    min-width: 150px;

    &.percent-total {
      position: relative;
      left: -25%;
    }
  }

  & .process {
    font-size: 16px;
    order: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    z-index: 10;

    & > div:first-child {
      z-index: 10;
      text-align: left;
    }
  }
}
#dashboard .main-content .inner-total > .percent:nth-child(1) > span {
  // text-align: left !important;
  padding-left: 20px;
}
#dashboard .main-content .inner-total > .percent:nth-child(1) > div {
  text-align: right !important;
}
.modal {
  z-index: 1000;
  &.edit-project {
    .modal-inner {
      padding-bottom: 40px;
      .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none;
      }
    }
    h3 {
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      color: #3cb371;
      margin-bottom: 20px;
    }
    .form-group {
      + .form-group {
        margin-top: 17px;
      }
      label {
        color: $text-main;
        margin-bottom: 10px;
      }
      .w-219 {
        width: 219px;
        @include br739 {
          width: 100%;
        }
      }
      .w-206 {
        width: 206px;
        @include br739 {
          width: 100%;
        }
      }
      input {
        color: $line;
      }
    }
    .btn {
      margin: 0 auto;
      display: block;
      @include br739 {
        width: 100%;
      }
    }
    .btn-delete-project {
      width: 180px;
      height: 30px;
      background-color: #f86767;
      color: #fff;
      font-size: 14px;
      border: 1px solid #f86767;
      margin-top: 12px;
    }
    .btn-small {
      margin-top: -3px;
    }
    .project-warning {
      margin: 20px 0;
      color: #F86767;
    }
    .w-219 {
      width: 219px;
    }
    .w-197 {
      width: 197px;
    }
    .w-206 {
      width: 206px;
    }
    .w-480 {
      width: 480px;
    }
    .h-38 {
      height: 38px;
    }
    .transition-rate {
      & .ant-form-item-control-input-content {
        display: flex;
        align-items: flex-end;

        & .ant-select-selector {
          text-align: center;
        }

        & > .ant-form-item {
          margin-bottom: -5px;

          &.ant-form-item-has-error {
            position: relative;
            margin-bottom: 20px;

            & + span {
              margin-bottom: 30px;
            }

            & .ant-form-item-explain {
              position: absolute;
              bottom: -40px;
            }

            & ~ .has-error {
              margin-bottom: 20px;
            }
          }

          & input, & .ant-select-selection-search, & .ant-select-selector {
            height: 38px;
          }
        }

        & > span {
          display: inline-block;
          margin: 0 8px 5px;
          font-size: 14px;
        }

      }
    }
    & .ant-row:nth-child(3) {
      margin-top: -8px;

      & input, & .ant-select-selection-search, & .ant-select-selector {
        height: 38px;
      }
    }
  }
  &.about-setting {
    .modal-body {
      width: 720px;
    }
    .modal-inner {
      max-height: 75vh;
    }
    h3 {
      font-size: 16px;
      font-weight: 700;
      color: $text-main;
      margin-bottom: 21px;
    }
    .tabs {
      .nav-tabs {
        // display: flex;
        flex-wrap: wrap;
        // border-bottom: 1px solid #ccc;
        li {
          padding: 0 11px 3px;
          position: relative;
          &.active {
            &::after {
              content: '';
              width: 100%;
              height: 1px;
              background-color: $main-color;
              position: absolute;
              bottom: -1px;
              left: 0;
              z-index: 1;
            }
            a {
              color: $main-color;
            }
          }
          a {
            font-size: 16px;
            font-weight: 700;
            color: $text-disable;
          }
        }
      }
      .tab-content {
        & .tab-content-item {
          & > h3 {
            font-weight: 700;
            font-size: 16px;
            color: $main-color;
            margin-bottom: 16px;
            margin-top: 16px;
          }

          .youtube-wrapper {
            position: relative;
            padding-bottom: 52.25%;

            // padding-bottom: 56.25%;
            /* 16:9 */
            padding-top: 25px;
            height: 0;
          }

          .youtube-wrapper iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000 !important;
          }

          & .w-100 {
            width: 100%;
            height: 100%;

            &.template-youtube {
              min-height: 330px;
            }
          }

          & .template-image {
            display: flex;
            align-items: flex-end;

            & .btn-remove-image {
              border: 1px solid $alert;
              color: $alert;
              margin-left: 10px;
              width: 75px;
              height: 38px;
              font-size: 13px;
            }
          }
        }
        .btn-add-template {
          margin-top: 20px;
          width: 107px;
          height: 30px;
          color: #fff;
          background: $main-color;
          border: 1px solid $main-color;
        }
        .btn-add-image {
          width: 92px;
          height: 32px;
          border: 1px solid $line;
          background: $line;
          color: #fff;
          display: flex;
          align-items: center;

          span {
            margin-left: 11px;
          }
        }
        .note {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          .avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
          }
          .name {
            font-size: 14px;
            font-weight: 700;
            color: $text-main;
            margin-left: 8px;
          }
          .time {
            font-size: 14px;
            margin-left: 15px;
            color: $text-disable;
          }
          .delete {
            display: flex;
            margin-left: auto;
            margin-right: 8px;
            border: none;
            background-color: #fff;
            &:hover {
              path {
                fill: $main-color;
              }
            }
          }
        }
        .tab-1 {
          width: 100%;
          p {
            font-size: 14px;
            color: $text-main;
            line-height: 22.4px;
            margin-bottom: 8px;
            word-wrap: break-word;
          }
          .image {
            margin-bottom: 12px;
          }
          textarea {
            height: 60px;
            padding: 12px 13px;
            font-size: 14px;
            color: #1b1b1b;
            // ::placeholder {
            //   color: #cccc !important;
            // }
          }

          .btn-image {
            margin-top: 4px;
            margin-bottom: 16px;
            &.custom-upload {
              .ant-upload-list-item-thumbnail {
                pointer-events: none;
              }
            }
            button {
              background: #ccc;
              display: inline-flex;
              color: #fff;
              font-size: 14px;
              font-weight: 400;
              align-items: center;
              border-radius: 3px;
              border: 0;
              outline: none;
              padding: 0;
              cursor: pointer;
            }
          }
          .btn-post {
            text-align: center;
            a {
              @include br739 {
                width: 100%;
              }
              color: #fff;
            }
          }
          .project-ticket {
            background: #fff;
            border: 1px solid $line;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            span {
              width: 12px;
              height: 9.5px;
              color: $line;
            }

            &:hover {
              border-color: $main-color;

              span {
                color: $main-color;
              }
            }
          }
          .group-btn {
            margin-left: auto;
            display: flex;
            align-items: center;
          }
          .delete {
            margin-right: 13px;
            height: auto;
            padding: 0;
            color: $text-disable;
            margin-left: 0;
          }
          .btn-add-image {
            width: 92px;
            height: 32px;
            border: 1px solid $line;
            background: $line;
            color: #fff;
            display: flex;
            align-items: center;
            margin-top: 8px;

            span {
              margin-left: 11px;
            }
          }
          .comment-bottom {
            margin-top: 16px;
            & .ant-form-item-control-input-content {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-flow: column wrap;

              & .ant-checkbox-wrapper {
                & .ant-checkbox {
                  & .ant-checkbox-inner {
                    border-color: $main-color;
                  }
                }
                & > span:last-child {
                  color: $main-color;
                  font-weight: 400;
                  font-size: 16px;
                }
              }

              button {
                width: 107px;
                height: 30px;
                color: #fff;
                background: $main-color;
                margin-top: 16px;
              }
            }
          }
          .project-image {
            margin-top: 16px;

            &
              > .ant-form-item-control
              > .ant-form-item-control-input
              > .ant-form-item-control-input-content {
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-between;
              align-items: flex-end;

              & .ant-avatar {
                width: 100%;
                height: 100%;
              }

              & button {
                border: 1px solid $alert;
                color: $alert;
                margin-left: 10px;
              }
            }
          }
        }
        .tab-3 {
          h3 {
            font-size: 16px;
            font-weight: 700;
            color: $main-color;
            margin-bottom: 18px;
            .see-more {
              font-size: 14px;
              font-weight: 400;
              display: inline-flex;
              align-items: center;
              margin-left: 9px;
              svg {
                margin-left: 7px;
              }
            }
          }
          .message {
            margin-top: 3px;
            margin-bottom: 18px;
            p {
              font-size: 14px;
              line-height: 22.4px;
              color: $text-main;
            }
          }
          .video {
            margin-bottom: 16px;
            .w-100 {
              height: 35vh;
            }
          }
        }
      }
    }
  }
  &.video-only {
    .modal-body {
      width: 720px;
      .modal-inner {
        padding: 0;
      }
    }
  }
  .modal-inner {
    padding-bottom: 40px;
    @include br739 {
      padding: 25px;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: $line;
    }
  }
  .alert {
    color: $alert;
    font-size: 14px;
    margin-top: 18px;
    margin-bottom: 20px;
  }
  .form-group.error {
    input {
      border-color: $alert;
    }
  }
}