.ant-form-horizontal {
  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ant-input-affix-wrapper {
    padding: 0;
    border-radius: 3px;
    border: 1px solid $line;

    &:focus,
    &:hover {
      box-shadow: none !important;
      border: 1px solid $line;
    }
  }

  .ant-input-suffix {
    margin-left: 0;
  }

  .ant-form-item {
    margin-bottom: 0;

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
      border-color: $alert;
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    z-index: 0;
    border-color: $line;
    box-shadow: none;
  }
}

.ant-form-item-explain-error {
  text-align: left;
  margin-top: 5px;
  color: $alert !important;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless) {
  border-color: $alert !important;
}
.ant-input {
  &:hover {
    box-shadow: none;
    border-color: $line;
  }
  &:focus {
    border-color: #60bf87 !important;
    box-shadow: 0 0 0 2px rgb(60 179 113 / 20%) !important;
    border-right-width: 1px !important;
    outline: 0 !important;
  }
  font-family: "Noto Sans", "Noto Sans JP" !important;
}

.ant-select-dropdown {
  @include br739 {
    left: 50% !important;
    transform: translateX(-50%);
  }
}
#campaign-management {
  .ant-table-thead {
    th {
      background-color: transparent;
      &::before {
        content: none;
      }
    }
  }
  .ant-table-tbody {
    button {
      background-color: #fff;
      border: none;
      cursor: pointer;
    }
    & > tr > td {
      word-break: break-all;
      white-space: unset;
    }
  }
}
.ant-modal-wrap {
  display: flex;
  align-items: center;
  z-index: 100000 !important;

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $alert !important;
  }
}
