#authentication {
  color: #372c1f;
  &.page-forgot-password {
    .sign-form {
      .desc {
        font-size: 16px;
      }
      .btn-submit {
        width: 299px;
        max-width: 100%;
        @include br739 {
          width: 100%;
        }
      }
      .link-registration {
        margin-top: 31px;
      }
    }
  }
  &.page-reset-password {
    .sign-form {
      .desc {
        margin-left: -16px;
        margin-right: -16px;
        font-size: 16px;
      }
      .btn-submit {
        width: 215px;
        @include br739 {
          width: 100%;
        }
      }
    }
    .email-expired {
      text-align: center;
      padding-top: 122px;
      h3 {
        color: $main-color;
        margin-bottom: 24px;
        font-weight: 700;
        font-size: 20px;
      }
      p {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }
  &.page-change-password {
    .wrapper {
      padding: 82px 0;
      margin-left: 0;
    }
    .sign-form {
      padding-top: 14px;
      .btn-submit {
        width: 97px;
        @include br739 {
          width: 100%;
        }
      }
    }
  }
  &.page-changed-password {
    .btn-to-dashboard {
      width: 198px;
      color: #fff;
      border: 1px solid $main-color;
      height: 42px;
      line-height: 42px;
    }
    .sign-form .desc {
      margin-bottom: 20px;
    }
  }
  .sign-form {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 64px;
    padding-bottom: 64px;
    @include br739 {
      padding-top: 85px;
      padding-bottom: 85px;
    }
    .image {
      img {
        z-index: 10;
        width: 100%;
      }
    }
    label {
      text-align: left;
      color: $text-main;
    }
    .title {
      text-align: center;
      margin-bottom: 24px;
      h3 {
        font-size: 20px;
        color: #2e8b57;
        margin-bottom: 7px;
        font-weight: 700;
        letter-spacing: 0.05em;
        margin-top: 15px;
      }
      .error-msg {
        font-size: 14px;
        margin-top: 24px;
        color: $alert;
      }
    }
    .sub-title {
      text-align: center;
      font-weight: 700;
      margin-bottom: 8px;
      font-size: 16px;
    }
    .desc {
      letter-spacing: 0.04em;
      text-align: center;
      line-height: 1.65;
      margin-bottom: 25px;
      color: $text-main;
    }
    .confirm-forgot-desc {
      line-height: 1.8;
      font-size: 16px;
    }
    .confirm-forgot-button {
      margin-top: 21px;
    }
    .form-group {
      margin-bottom: 16px;
      .row {
        margin-left: -6px;
        margin-right: -6px;
        .col-6 {
          padding-left: 6px;
          padding-right: 6px;
        }
      }
      input {
        width: 100%;
        background: #ffffff;
        height: 38px;
        padding: 0 11px;
        font-size: 14px;
        border-radius: 3px;
        color: $text-main;
        letter-spacing: 0.05em;
        &.form-control:focus {
          box-shadow: none;
          border: 1px solid $line;
        }
        &.form-control:hover {
          border: 1px solid $line;
        }
        &::placeholder {
          color: #cccccc;
          font-family: 'Noto Sans JP';
        }
      }
      .anticon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
        z-index: 1;
        color: #3cb371;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    .btn-submit {
      width: 131px;
      margin-top: 3px;
      margin-bottom: 0;
      height: 42px;
      color: #fff;
      border: 1px solid $main-color;
      @include br739 {
        width: 100%;
      }
    }
    .has-account,
    .link-registration {
      color: $main-color;
      margin-top: 24px;
      text-align: center;
      font-size: 16px;
    }
    .btn-round {
      padding: 0 21px;
      font-size: 16px;
      letter-spacing: 0.12em;
    }
    .link-registration {
      margin-top: 22px;
    }
  }
}
