#cockpit-list {
  padding: 78px 0;
  .container {
    max-width: 1032px;
    padding-left: 12px;
    padding-right: 12px;
    > .row {
      margin-left: -12px;
      margin-right: -12px;
      .col-4 {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: $text-accent;
  }
  .cockpit-box {
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding: 33px 31px 33px;
    color: $text-main;
    margin-bottom: 24px;
    cursor: pointer;
    width: 320px;
    font-family: "Noto Sans", "Noto Sans JP" !important;
    a {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 8px;
      color: $text-main;
      display: inline-block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    p {
      font-size: 14px;
      line-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      height: 125px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  .group-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-create {
      width: 232px;
      margin-bottom: 24px;
      line-height: 42px !important;
      height: 42px;
      color: #fff;
      border: 1px solid $main-color;
      margin-right: 0;
      padding: 0;
      font-size: 16px;
      &:hover {
        color: #fff;
      }
    }
  }
  .btn-logout {
    display: block;
    text-align: center;
    color: $main-color;
    margin-top: 3px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
}
